import React, {useEffect, useState} from "react";
import CommanDataTable from "../../Components/Common/DataTable";
import {Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Label, Row, Tooltip} from "reactstrap";
import EmployeeSelect from "../../Components/Common/EmployeeSelect";
import TeamSelect from "../../Components/Common/TeamSelect";
import {DatePickerWithoutTimes} from "../../Components/Common/Flatpicker";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {formatDate} from "../../Components/Common/FormDateTime";
import {getPreviousDate, setCurrentDate} from "../../Components/Common/DefaultDateSet";
import {APIClient} from "../../helpers/api_helper";
import {EMPLOYEE_DATATABLE, NEVER_ATTENDED_REPORT} from "../../helpers/url_helper";
import {formatDuration} from "../../Components/Common/convertTimestampToTime";
import warningimg from "../../assets/images/remove.png";
import useractive from "../../assets/images/accept.png";
const api = new APIClient();

const AgentTable = () => {
    //search field state
    const [isLoading, setIsLoading] = useState(true);
    // Pagination settings
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [query, setQuery] = useState("");
    const [debouncedQuery, setDebouncedQuery] = useState("");
    const previewsDate = getPreviousDate();
    const currentDate = setCurrentDate();

    //select team state
    const [teamIndu, setTeamIndu] = useState([]);

    // date picker state
    const [selectedFromDate, setSelectedFromDate] = useState(previewsDate);
    const [selectedToDate, setSelectedToDate] = useState(currentDate);

    const [EmployeeData, setEmployeeData] = useState([]);

    // employee select
    const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);

    // table data state
    const [neverAttendData, setNeverAttendData] = useState([]);
    const [EmployeeDefault, setEmployeeDefault] = useState([]);

    // From Date
    const handleFromDateChange = (dateStr) => {
        setSelectedFromDate(dateStr);
    };

    // To date
    const handleToDateChange = (dateStr) => {
        setSelectedToDate(dateStr);
    };

    const letterColors = {
        A: "#3975d8",
        B: "#a1bfff",
        C: "#d4bf56",
        D: "#8bc070",
        E: "#47b0a9",
        F: "#6e5b7c",
        G: "#3ca06a",
        H: "#98999b",
        I: "#aa90b3",
        J: "#95950e",
        K: "#408d79",
        L: "#992b2e",
        M: "#ee9c64",
        N: "#3488a4",
        O: "#cc5984",
        P: "#d09d17",
        Q: "#cc5984",
        R: "#4b66c5",
        S: "#72252d",
        T: "#457425",
        U: "#5d6066",
        V: "#6d8a51",
        W: "#1a5985",
        X: "#cca9f6",
        Y: "#dfb432",
        Z: "#2da9a6",
    };

    const [tooltipOpenAgent, setTooltipAgent] = useState(false);

    const toggleAgent = () => setTooltipAgent(!tooltipOpenAgent);

    const [tooltipOpenUnique, setTooltipOpen] = useState(false);
    const toggleUnique = () => setTooltipOpen(!tooltipOpenUnique);

    const [tooltipOpenDuration, setTooltipDuration] = useState(false);
    const toggleDuration = () => setTooltipDuration(!tooltipOpenDuration);

    const [tooltipOpenAverage, setTooltipAverage] = useState(false);
    const toggleAverage = () => setTooltipAverage(!tooltipOpenAverage);

    const [tooltipOpenIncoming, setTooltipIncoming] = useState(false);
    const toggleIncoming = () => setTooltipIncoming(!tooltipOpenIncoming);

    const [tooltipOpenOutgoing, setTooltipOutgoing] = useState(false);
    const toggleOutgoing = () => setTooltipOutgoing(!tooltipOpenOutgoing);

    const [tooltipOpenMissed, setTooltipMissed] = useState(false);
    const toggleMissed = () => setTooltipMissed(!tooltipOpenMissed);

    const [tooltipOpenRejected, setTooltipRejected] = useState(false);
    const toggleRejected = () => setTooltipRejected(!tooltipOpenRejected);

    const [tooltipOpenNotClient, setTooltipNotClient] = useState(false);
    const toggleNotClient = () => setTooltipNotClient(!tooltipOpenNotClient);

    const [tooltipOpenConnected, setTooltipConnected] = useState(false);
    const toggleConnected = () => setTooltipConnected(!tooltipOpenConnected);

    const [tooltipOpenAvg, setTooltipOpenAvg] = useState(false);

    const toggleAvg = () => {
        setTooltipOpenAvg((prev) => !prev);
    };

    const [tooltipOpenTotalCall, setTooltipOpenTotalCall] = useState(false);

    // Toggle function to change the tooltip state
    const toggleTotalCall = () => {
        setTooltipOpenTotalCall(!tooltipOpenTotalCall);
    };

    // Step 1: Declare state for the tooltip
    const [tooltipOpenActiveee, setTooltipOpenActiveee] = useState(false);

    // Step 2: Define the toggle function
    const toggleActiveee = () => setTooltipOpenActiveee(!tooltipOpenActiveee);

    const [tooltipOpenWarning, setTooltipOpenWarning] = useState(false);
    const toggleWarningTooltip = () => setTooltipOpenWarning(!tooltipOpenWarning);

    const [tooltipZeroOpenWarning, setTooltipZeroOpenWarning] = useState(false);
    const toggleZeroWarningTooltip = () => setTooltipZeroOpenWarning(!tooltipZeroOpenWarning);

    const [tooltipOpenLogiTech, setTooltipOpenLogiTech] = useState(false);
    const toggleLogiTech = () => setTooltipOpenLogiTech(!tooltipOpenLogiTech);

    const column = [
        {
            name: <span> SN.</span>,
            selector: (row) => row.serialNumber,
            sortable: true,
            width: "55px",
        },

        {
          name: (
              <span className="font-weight-bold fs-13 d-flex">
                  <b>Agent Name</b>
              </span>
          ),
          selector: (row) => row?.fullName,
          width: "220px",
          cell: (row) => {
              return (
                  <div
                      className="d-flex justify-content-between align-items-center mt-2 mb-2"
                      style={{width: "100%"}}
                  >
                      <div className="d-flex align-items-center">
                          {row?.img ? (
                              <>
                                  <img
                                      src={row?.img}
                                      alt=""
                                      style={{
                                          width: "35px",
                                          height: "35px",
                                          objectFit: "cover",
                                          borderRadius: "50%",
                                          marginRight: "10px",
                                      }}
                                  />
                                  <img
                                      className="userstatus-onoff"
                                      src={warningimg}
                                      alt="Warning"
                                      style={{left: "40px", bottom: "8px"}}
                                  />
                              </>
                          ) : (
                              <div className="position-relative d-inline-block sn-tble-profile-dp">
                                  <div
                                      className="rounded-circle img-thumbnail"
                                      style={{background: letterColors[row?.firstLetter]}}
                                  >
                                      <span style={{color: "#fff"}}>{row?.firstLetter}</span>
                                  </div>
                                  <div>
                                      {row?.todayActiveStatus === 0 ? (
                                          row?.zerosTimestap ? (
                                              <>
                                                  <img className="userstatus-onoff" src={warningimg} alt="Warning" />
                                              </>
                                          ) : (
                                              ""
                                          )
                                      ) : (
                                          <img src={useractive} alt="User Active" className="userstatus-onoff" />
                                      )}
                                  </div>
                              </div>
                          )}
                          <h5
                              style={{
                                  textTransform: "capitalize",
                                  margin: 0,
                                  fontSize: "small",
                                  fontWeight: "900",
                                  color: "#333333",
                              }}
                          >
                              {row?.fullName}
                              <br />
                              <span className="profile-lastseen">{row?.appVersion}</span>
                          </h5>
                      </div>
                  </div>
              );
          },
          sortable: true,
      },
        {
            name: <span>Teams</span>,
            selector: (row) => row.team,
            cell: (row) => <div style={{color: "#333", fontWeight: "600", textAlign: "left"}}>{row.team}</div>,
            sortable: true,
            width: "120px",
        },

        {
            name: (
                <span data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideUnique">
                    Unique Call <span className="ri-information-line sn-dashboard-tble-toll"></span>
                    <div>
                        <Tooltip
                            placement="top"
                            isOpen={tooltipOpenUnique}
                            autohide={false}
                            target="DisabledAutoHideUnique"
                            toggle={toggleUnique}
                        >
                            Shows unique numbers contacted, tracking the exact number of clients reached by your
                            employees.
                        </Tooltip>
                    </div>
                </span>
            ),
            selector: (row) => row.uniqueClient,
            sortable: true,
            width: "120px",
        },
        {
            name: (
                <span data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideDuration">
                    Total Duration
                    <span className="ri-information-line sn-dashboard-tble-toll"></span>
                    <div>
                        <Tooltip
                            placement="top"
                            isOpen={tooltipOpenDuration}
                            autohide={false}
                            target="DisabledAutoHideDuration"
                            toggle={toggleDuration}
                        >
                            Total Call Duration shows the total time spent on all incoming and outgoing calls
                        </Tooltip>
                    </div>
                </span>
            ),
            selector: (row) => row.totalDuration,
            sortable: true,
        },

        {
            name: (
                <span data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideAverage">
                    Avg Call Duration
                    <span className="ri-information-line sn-dashboard-tble-toll"></span>
                    <div>
                        <Tooltip
                            placement="top"
                            isOpen={tooltipOpenAverage}
                            autohide={false}
                            target="DisabledAutoHideAverage"
                            toggle={toggleAverage}
                        >
                            Average Call Duration represents the average time spent on each connected incoming and
                            outgoing call.
                        </Tooltip>
                    </div>
                </span>
            ),
            selector: (row) => row.avrerageCallDuration,
            sortable: true,
        },

        {
            name: (
                <span data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideTotalCall">
                    Total Call
                    <span className="ri-information-line sn-dashboard-tble-toll"></span>
                    <div>
                        <Tooltip
                            placement="top"
                            isOpen={tooltipOpenTotalCall}
                            autohide={false}
                            target="DisabledAutoHideTotalCall"
                            toggle={toggleTotalCall}
                        >
                            Total Call represents the total number of incoming and outgoing calls made.
                        </Tooltip>
                    </div>
                </span>
            ),
            selector: (row) => (row.totalCall === "-" ? 0 : row.totalCall),
            cell: (row) => <span className="sn-total-callbg">{row.totalCall}</span>,
            sortable: true,
        },

        {
            name: (
                <span data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideConnected">
                    Connected Call
                    <span className="ri-information-line sn-dashboard-tble-toll"></span>
                    <div>
                        <Tooltip
                            placement="top"
                            isOpen={tooltipOpenConnected}
                            autohide={false}
                            target="DisabledAutoHideConnected"
                            toggle={toggleConnected}
                        >
                            Shows unique numbers contacted, tracking the exact number of clients reached by your
                            employees
                        </Tooltip>
                    </div>
                </span>
            ),
            selector: (row) => row.connectedCalls || "-",
            cell: (row) => <span className="sn-total-total-conected">{row.connectedCalls}</span>,
            sortable: true,
        },

        {
            name: (
                <span data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideIncoming">
                    Incoming Call
                    <span className="ri-information-line sn-dashboard-tble-toll"></span>
                    <div>
                        <Tooltip
                            placement="top"
                            isOpen={tooltipOpenIncoming}
                            autohide={false}
                            target="DisabledAutoHideIncoming"
                            toggle={toggleIncoming}
                        >
                            Displays the total number of calls received by your team during the selected time period.
                        </Tooltip>
                    </div>
                </span>
            ),
            selector: (row) => row.totallIncomingCall || "-",
            cell: (row) => <span className="sn-total-incoming-callbg">{row.totallIncomingCall}</span>,
            sortable: true,
        },

        {
            name: (
                <span data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideOutgoing">
                    Outgoing Call
                    <span className="ri-information-line sn-dashboard-tble-toll"></span>
                    <div>
                        <Tooltip
                            placement="top"
                            isOpen={tooltipOpenOutgoing}
                            autohide={false}
                            target="DisabledAutoHideOutgoing"
                            toggle={toggleOutgoing}
                        >
                            Shows the total number of calls made by your team within the selected time period.
                        </Tooltip>
                    </div>
                </span>
            ),
            selector: (row) => row.totallOutgoingCall || "-",
            cell: (row) => <span className="sn-total-outgoing-callbg">{row.totallOutgoingCall}</span>,
            sortable: true,
        },

        {
            name: (
                <span data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideMissed">
                    Missed Call
                    <span className="ri-information-line sn-dashboard-tble-toll"></span>
                    <div>
                        <Tooltip
                            placement="top"
                            isOpen={tooltipOpenMissed}
                            autohide={false}
                            target="DisabledAutoHideMissed"
                            toggle={toggleMissed}
                        >
                            Represents the number of incoming calls that were not answered by your team during the
                            specified period.
                        </Tooltip>
                    </div>
                </span>
            ),
            selector: (row) => row.totallMissedCall || "-",
            cell: (row) => <span className="sn-total-missed-callbg">{row.totallMissedCall}</span>,
            sortable: true,
        },

        {
            name: (
                <span data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideAvg">
                    Not Attempted Call
                    <span className="ri-information-line sn-dashboard-tble-toll"></span>
                    <div>
                        <Tooltip
                            placement="top"
                            isOpen={tooltipOpenAvg}
                            autohide={false}
                            target="DisabledAutoHideAvg"
                            toggle={toggleAvg}
                        >
                            This shows missed calls you haven’t tried to call back yet. They will stay as 'Not
                            Attempted' until you make a call.
                        </Tooltip>
                    </div>
                </span>
            ),
            selector: (row) => row?.totalNeverAttended,
            cell: (row) => <span className="sn-total-notattemped-callbg">{row?.totalNeverAttended}</span>,
            sortable: true,
        },

        {
            name: (
                <span data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideRejected">
                    Rejected Call
                    <span className="ri-information-line sn-dashboard-tble-toll"></span>
                    <div>
                        <Tooltip
                            placement="top"
                            isOpen={tooltipOpenRejected}
                            autohide={false}
                            target="DisabledAutoHideRejected"
                            toggle={toggleRejected}
                        >
                            Indicates the number of incoming calls that were actively declined by your team.
                        </Tooltip>
                    </div>
                </span>
            ),
            selector: (row) => row.totalRejectedCall || "-",
            cell: (row) => <span className="sn-total-rejected-callbg">{row.totalRejectedCall}</span>,
            sortable: true,
        },

        {
            name: (
                <span data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideNotClient">
                    Not Picked Up By Client
                    <span className="ri-information-line sn-dashboard-tble-toll"></span>
                    <div>
                        <Tooltip
                            placement="top"
                            isOpen={tooltipOpenNotClient}
                            autohide={false}
                            target="DisabledAutoHideNotClient"
                            toggle={toggleNotClient}
                        >
                            Not just outgoing calls. Shows dialed but unconnected calls. Numbers stay until you connect,
                            ensuring no missed contacts.
                        </Tooltip>
                    </div>
                </span>
            ),
            selector: (row) => row.totalNotPickedUpByClient || "-",
            cell: (row) => <span className="sn-total-not-picked-upby-clintng">{row.totalNotPickedUpByClient}</span>,
            sortable: true,
        },
    ];

    // Pagination handler
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    // flatPicker Date
    const formattedStartDate = formatDate(selectedFromDate);
    const formattedEndDate = formatDate(selectedToDate);

    // Search field handler
    const handleSearch = (e) => {
        setQuery(e.target.value);
    };

    useEffect(() => {
        if (EmployeeDefault.length > 0) {
            const defaultId = EmployeeDefault.map((option) => option?.id);
            fetchAllData(currentPage, perPage, defaultId, "");
        }
    }, [currentPage, perPage, EmployeeDefault]);

    const onShowData = () => {
        if (selectedEmployeeOptions.length > 0) {
            const updatedIds = selectedEmployeeOptions.map((userid) => userid?.id);
            fetchAllData(currentPage, perPage, "", updatedIds);
            setCurrentPage(1);
            setPerPage(10);
        }
    };

    const fetchAllData = async (page, size = perPage, searchTerm = "") => {
        setIsLoading(true);
        try {
            const updatedIds = selectedEmployeeOptions.map((userid) => userid?.id);
            let data = {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                employeeIds: updatedIds,
            };
            const response = await api.create(`${EMPLOYEE_DATATABLE}?pg=${page}&lm=${size}&query=${searchTerm}`, data);
            if (response?.success) {
                const apiResponse = response.data;
                const tableData = (apiResponse || [])?.map((data, index) => {
                    const fname = data?.fullName;
                    const firstLetter = fname.charAt(0).toUpperCase();

                    return {
                        serialNumber: (currentPage - 1) * perPage + index + 1,
                        img: data?.imageUrl,
                        firstLetter: fname ? firstLetter : "",
                        fullName: data?.fullName,
                        
                        todayActiveStatus: data?.todayActiveStatus,
                        team: data?.team,
                        totalCall: data.totalCall || "-",
                        totalDuration: data.totalDuration
                            ? new Date(data.totalDuration * 1000).toISOString().slice(11, 19)
                            : "-",
                        totallIncomingCall: data.totalIncomingCall || "-",
                        totallIncomingConnectedCall: data.totalIncomingConnectedCall || "-",
                        totallOutgoingCall: data.totalOutgoingCall || "-",
                        totallOutgoingConnectedCall: data.totalOutgoingConnectedCall || "",
                        totallMissedCall: data.totalMissedCall || "-",
                        totalRejectedCall: data.totalRejectedCall || "-",
                        totalNotPickedUpByClient: data.totalNotPickedUpByClient || "-",
                        totalNeverAttended: data.neverAttended,
                        avrerageCallDuration: formatDuration(data.avrerageCallDuration) || "-",
                        uniqueClient: data.uniqueClient || "-",
                        connectedCalls: data.connectedCalls || "-",
                        totallIncomingCallDuration: data.totalIncomingCallDuration
                            ? new Date(data.totalIncomingCallDuration * 1000).toISOString().slice(11, 19)
                            : "-",
                        totallOutgoingCallDuration: data.totalOutgoingCallDuration
                            ? new Date(data.totalOutgoingCallDuration * 1000).toISOString().slice(11, 19)
                            : "",
                    };
                });
                setNeverAttendData(tableData);
                // setTotalRows(data?.employeeIds.length || 0);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    // clear field
    const resetInputHistory = () => {
        setSelectedToDate("");
        setSelectedFromDate("");
        setSelectedEmployeeOptions([]);
        setTeamIndu([]);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Agent Performance Report" pageTitle="Agent Table" />
                    <Row>
                        <Col lg={12}>
                            <Card className="h-100">
                                <CardBody>
                                    <Row className="sn-header-select-employess-home sn-top-inputs-heading">
                                        <div className="col-md-2 col-sm-12">
                                            <DatePickerWithoutTimes
                                                onChange={handleFromDateChange}
                                                getDate={selectedFromDate}
                                                labelContent="From Date"
                                            />
                                        </div>
                                        <div className="col-md-2 col-sm-12">
                                            <DatePickerWithoutTimes
                                                onChange={handleToDateChange}
                                                getDate={selectedToDate}
                                                labelContent="To Date"
                                            />
                                        </div>
                                        <div className="col-md-2 col-sm-12">
                                            <TeamSelect
                                                setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                                                teamIndu={teamIndu}
                                                setTeamIndu={setTeamIndu}
                                                labelContent="Select Team"
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <EmployeeSelect
                                                EmployeeDefault={EmployeeDefault}
                                                setEmployeeDefault={setEmployeeDefault}
                                                teamIndu={teamIndu}
                                                selectedEmployeeOptions={selectedEmployeeOptions}
                                                setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                                                labelContent="Select Employee"
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <FormGroup className="mt-2">
                                                <Label for="exampleSelect"></Label>
                                                <div id="exampleSelect" className="sn-call-apply-btn-reset">
                                                    <Button
                                                        id="exampleSelect"
                                                        type="submit"
                                                        color="danger"
                                                        className=""
                                                        onClick={onShowData}
                                                        disabled={isLoading}
                                                    >
                                                        Apply
                                                    </Button>
                                                    <Button
                                                        className=""
                                                        style={{marginLeft: "10px"}}
                                                        id="exampleSelect"
                                                        outline
                                                        color="danger"
                                                        onClick={resetInputHistory}
                                                        disabled={isLoading}
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </Row>

                                    <Row className="sn-team-managment-tble sn-user-tble-manin-manage">
                                        
                                        <div style={{float:'left', width:'50%',}}>
                                        <div className="agentmain-page-tble-tooltip">
                                            <span
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                id="DisabledAutoLogiTech"
                                            >
                                                <span className="sales-ninja-tooltip-agent">
                                                    {" "}
                                                    <img
                                                        className="agent-tooltip-tble-i"
                                                        src={warningimg}
                                                        alt="Warning"
                                                    />
                                                </span>
                                                <div>
                                                    <Tooltip
                                                        placement="top"
                                                        isOpen={tooltipOpenLogiTech}
                                                        autohide={false}
                                                        target="DisabledAutoLogiTech"
                                                        toggle={toggleLogiTech}
                                                    >
                                                        Inactive since due to no calls attempted or app not opened.
                                                        Please ask the employee to open the app to sync calls and
                                                        recordings.
                                                    </Tooltip>
                                                </div>
                                            </span>
                                            <span className="activeeeeeeeeeeeee">
                                                <div
                                                    style={{
                                                        fontSize: "15px",
                                                        marginLeft: "13px",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <img
                                                    src={useractive}
                                                    alt="User Active"
                                                    className="agent-tooltip-tble-i"
                                                    id="DisabledAutoHideActiveee"
                                                />
                                                <Tooltip
                                                    placement="top"
                                                    isOpen={tooltipOpenActiveee}
                                                    autohide={false}
                                                    target="DisabledAutoHideActiveee"
                                                    toggle={toggleActiveee}
                                                >
                                                    Employee is currently active and making calls.
                                                </Tooltip>
                                            </span>
                                        </div>
                                        </div>

                                        <Col lg={12} className="agent-main-page-tble-sec input">
                                            <CommanDataTable
                                                handleSearch={handleSearch}
                                                column={column}
                                                searchText={query}
                                                tableData={neverAttendData}
                                                handlePerRowsChange={handlePerRowsChange}
                                                totalRows={totalRows}
                                                currentPage={currentPage}
                                                isLoading={isLoading}
                                                handlePageChange={handlePageChange}
                                                inputClass={
                                                    neverAttendData && neverAttendData.length > 0 ? "d-block" : "d-none"
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AgentTable;
