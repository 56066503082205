import React, { useState, useEffect } from "react";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_PROFILE,
  EMPLOYEE_DELETE_PROFILE,
  EMPLOYEE_PUT_UPLOAD_PROFILE,
  POST_GET_INDUSTRY_TYPE,
  PUT_UPLOAD_PROFILE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_EMPLOYEE,
} from "../../helpers/url_helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
//import images
import progileBg from "../../assets/images/profile-bg.jpg";
import ChangePassword from "./Change-password";
import { APIClient } from "../../helpers/api_helper";
import Toast from "../../Components/Common/Toast";
import axios from "axios";
import moment from "moment";
import { ConvertTimestampToTime } from "../../Components/Common/convertTimestampToTime";
import salesninjaicon from "../../assets/images/sales-ninja-icon.png";
import { getProfileRequest } from "../../store/auth/profile/actions";
const api = new APIClient();

const UserProfile = () => {
  const navigate = useNavigate();
  const { update_type } = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => ({
    data: state?.Profile?.profileData,
  }));

  const [profileImg, setProfileImg] = useState([]);
  const [previewUrl, setPreviewUrl] = useState(null);
  // ACCESS TOKEN

  const accessToken = JSON.parse(localStorage.getItem("authUser"));
  const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneNumberRules = /^[1-9]\d{9}$/;

  const [profileData, setProfileData] = useState();

  const [industryTypeData, setIndustryTypeData] = useState();
  //field state
  const [organizationName, setOrganizationName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [postal, setPostal] = useState("");
  const [code, setCode] = useState("");
  const [country, setCountry] = useState("");
  const [stateData, setStateData] = useState("");
  const [city, setCity] = useState("");
  const [industryType, setIndustryType] = useState();
  const [websites, setWebsite] = useState();
  const [activeTab, setActiveTab] = useState("1");

  const [employeeName, setEmployeeName] = useState([])
  // const postalCode = postal?.toString();
  const storedValue = JSON.parse(localStorage.getItem("logintype"));

  const formik = useFormik({
    initialValues: {
      organizationName: storedValue === 'organization' ? (organizationName || "") : "",
      fullName: storedValue === 'employee' ? (employeeName || "") : "",
      email: email || "",
      phone: phone || "",
      pincode: postal?.toString() || "",
      mobileNumber: mobileNumber || "",
      stateData: stateData || "",
      city: city || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      ...(storedValue === 'organization' && {
        organizationName: Yup.string().required(
          "Please Enter Your organizationName"
        ),
      }),

      // Validation for Employee login
      ...(storedValue === 'employee' && {
        fullName: Yup.string().required("Please Enter Your full Name"),
      }),

      email: Yup.string()
        .email("Please Enter a valid email address")
        .matches(emailRules, { message: "  Invalid email" })
        .required("Please Enter your email"),

      ...(storedValue === 'organization' && {
        phone: Yup.string()
          .matches(phoneNumberRules, {
            message: "Please enter 10 digit Phone number",
          }).required("Please enter your Phone no")
      }),

      mobileNumber: Yup.string()
        .matches(phoneNumberRules, {
          message: "Please enter 10 digit mobile number",
        }).required("Please enter your mobile no"),

      ...(storedValue === 'organization' && {
        pincode: Yup.string()
          .min(6, "Should be 6 digits long")
          .required("Postal Code must be filled out")
          .max(6, "should not exceed 6 digits")
      }),
      ...(storedValue === 'organization' && {
        stateData: Yup.string().required("State Name is required")
      }),
      ...(storedValue === 'organization' && {
        city: Yup.string().required("City Name is required")
      }),
    }),
    onSubmit: async (values) => {
      let response;
      let data;
      if (storedValue === 'employee') {
        data = {
          fullName: values.fullName,
        };
        response = await api.patch(`${UPDATE_PROFILE_EMPLOYEE}`, data);
      } else if (storedValue === 'organization') {
        data = {
          orgName: values.organizationName,
          pincode: values.pincode,
          city: values.city,
          state: values.stateData,
          country: country,
          phoneNo: values.phone,
          mobileNo: values.mobileNumber,
        };
        response = await api.patch(`${UPDATE_PROFILE}`, data);
      }
      toast.success(response?.message, { autoClose: 1000 });

      if (response?.success === true) {
        setTimeout(() => {
          navigate("/profile");
          // GetProfileData();
          dispatch(getProfileRequest(storedValue));
        }, 2000);
      }
    },
  });

  useEffect(() => {
    setProfileImg(data?.profileImageUrl);
    const time = ConvertTimestampToTime(data?.lastCallRecordingSync);
    const lastcallsync = ConvertTimestampToTime(data?.lastCallSync);
    setProfileData({ data, timestap: time, lastcallsync: lastcallsync });
    setEmployeeName(data?.fullName || "");
    setOrganizationName(data?.organizationName || "");
    setEmail(data?.email || "");
    setPhone(data?.phoneNumber || "");
    setCode(data?.countryCode || "");
    setMobileNumber(data?.mobileNumber || "");
    setPostal(data?.pincode || "");
    setCountry(data?.country || "");
    setStateData(data?.state || "");
    setCity(data?.city || "");
    setIndustryType(data?.industryType || "");
    setWebsite(data?.website || "");
  }, [data]);

  useEffect(() => {
    if (storedValue === "organization") {
      fetchUserData();
    }
  }, []);


  useEffect(() => {
    dispatch(getProfileRequest(storedValue));
  }, [dispatch, storedValue]);



  const fetchUserData = async () => {
    try {
      if (storedValue === "organization") {
        const res = await api.get(`${POST_GET_INDUSTRY_TYPE}`);
        if (res.success === true) {
          setIndustryTypeData(res?.data);
        }
      }
    } catch (error) {
      console.error("Error fetching industry types:", error);
      // Handle error as needed
    }
  };

  const handleWebInputChange = (e) => {
    const { value } = e.target;
    setWebsite(value);
  };

  const handleCountryCode = (e) => {
    const { value } = e.target;
    setCode(value);
  };

  const handleCountry = (e) => {
    const { value } = e.target;
    setCountry(value);
  };

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }
    const fileSizeInMB = file.size / (1024 * 1024);

    if (fileSizeInMB > 5) {
      Toast("Profile picture size should not exceed 5MB.", "error");
      event.target.value = null;
      return;
    }

    // Validate file type
    const validImageTypes = ["image/jpeg", "image/png"];
    if (!validImageTypes.includes(file.type)) {
      Toast(
        "Only image files (e.g., JPG, PNG) are supported for profile pictures.",
        "error"
      );
      return;
    }

    // Preview the image before upload
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
    // Set up the form data and headers
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    var formdata = new FormData();
    formdata.append("profileImage", file);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    let response;

    // Make the API call based on the user type
    if (storedValue === "employee") {
      response = fetch(
        `${process.env.REACT_APP_API_URL}${EMPLOYEE_PUT_UPLOAD_PROFILE}`,
        requestOptions
      );
    } else if (storedValue === "organization") {
      response = fetch(
        `${process.env.REACT_APP_API_URL}${PUT_UPLOAD_PROFILE}`,
        requestOptions
      );
    }

    // Handle the response
    response
      .then((response) => response.json())
      .then((data) => {
        toast.success(data?.message, { autoClose: 1000 });
        dispatch(getProfileRequest(storedValue)); // Refresh profile data
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const deleteImage = async () => {
    try {
      let config = {
        method: "delete",
        url: "",
      };

      if (storedValue === "employee") {
        config.url = `${process.env.REACT_APP_API_URL}${EMPLOYEE_DELETE_PROFILE}`;
      } else if (storedValue === "organization") {
        config.url = `${process.env.REACT_APP_API_URL}${DELETE_PROFILE}`;
      }
      const response = await axios(config);
      if (response?.success === true) {
        toast.success(response.message, { autoClose: 1000 });
        // GetProfileData();
        dispatch(getProfileRequest(storedValue));
      } else if (response?.data?.success === false) {
        toast.error(response.data.message, { autoClose: 1000 });
      }
    } catch (error) {
      console.log("error", error);
      toast.error("An error occurred while deleting the profile image", {
        autoClose: 1000,
      });
    }
  };

  return (
    <React.Fragment>
      {/* <ToastContainer /> */}
      <div className="page-content sn-profile-page-main">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={progileBg} className="profile-wid-img" alt="" />
              <div className="overlay-content">
                <div className="text-end p-3">
                  <div className="row">
                    <div className="col-ms-12 co-sm-12">
                      <div className="text-center sn-profile-image-updated">
                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                          <div>
                            <img
                              src={profileImg || salesninjaicon}
                              className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                              alt="Profile"
                            />
                            <button
                              className="sn-profile-avatar-pic"
                              onClick={deleteImage}
                            >
                              <i className="ri-close-fill"></i>
                            </button>
                          </div>

                          <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                            <Input
                              id="profile-img-file-input"
                              className="profile-img-file-input"
                              type="file"
                              accept="image/*"
                              onChange={handleFileInputChange}
                            />

                            <Label
                              htmlFor="profile-img-file-input"
                              className="profile-photo-edit avatar-xs"
                            >
                              <span className="avatar-title rounded-circle bg-light text-body">
                                <i className="ri-camera-fill"></i>
                              </span>
                            </Label>
                          </div>
                        </div>
                        <h5 className="mb-1 color-white">{organizationName}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col xxl={9} className="m-auto">
              <Card className="sn-user-profile-update">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0 sn-profile-user-top-icon"
                    role="tablist"
                  >
                    {update_type === "change-password" ? (
                      ""
                    ) : (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            tabChange("1");
                          }}
                        >
                          <i className="far fa-user"></i>
                          Personal
                        </NavLink>
                      </NavItem>
                    )}
                    {update_type === "personal" ? (
                      ""
                    ) : (
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            tabChange("2");
                          }}
                          type="button"
                        >
                          <i className="ri-lock-password-line"></i>
                          Change Password
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                </CardHeader>

                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Form
                        onSubmit={formik.handleSubmit}
                        className="needs-validation"
                        action="#"
                      >
                        {update_type === "personal" ? null : (
                          <div className="row justify-content-between">
                            <div className="col-auto">
                              <h4 className="mb-0">Details</h4>
                            </div>
                            <div className="col-auto">
                              <Button
                                color="danger"
                                className="rounded-pill"
                                onClick={() => {
                                  navigate("/profile/update/personal");
                                }}
                              >
                                Update{" "}
                              </Button>
                            </div>
                          </div>
                        )}
                        <Row>


                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="organizationname" className="form-label">
                                {storedValue === "employee" ? "Employee Name" : "Organization Name"}
                              </Label>

                              {update_type === "personal" ? (
                                <>
                                  {/* Employee Conditional Block */}
                                  {storedValue === 'employee' && (
                                    <>
                                      <Input
                                        name="fullName"
                                        type="text"
                                        placeholder="Enter Employee Name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.fullName}
                                        invalid={
                                          formik.touched.fullName && formik.errors.fullName ? true : false
                                        }
                                      />
                                      {formik.touched.fullName && formik.errors.fullName ? (
                                        <FormFeedback type="invalid">
                                          <div>{formik.errors.fullName}</div>
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}

                                  {/* Organization Conditional Block */}
                                  {storedValue !== 'employee' && (
                                    <>
                                      <Input
                                        name="organizationName"
                                        type="text"
                                        placeholder="Enter Organization Name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.organizationName}
                                        invalid={
                                          formik.touched.organizationName && formik.errors.organizationName
                                            ? true
                                            : false
                                        }
                                      />
                                      {formik.touched.organizationName && formik.errors.organizationName ? (
                                        <FormFeedback type="invalid">
                                          <div>{formik.errors.organizationName}</div>
                                        </FormFeedback>
                                      ) : null}
                                    </>
                                  )}
                                </>
                              ) : (
                                <div
                                  type="text"
                                  className="form-control fw-bold border-0 bg-light"
                                  id="organizationname"
                                >
                                  {storedValue === "employee"
                                    ? profileData?.data?.fullName
                                    : profileData?.data?.organizationName}
                                </div>
                              )}
                            </div>
                          </Col>




                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                Email
                              </Label>
                              {update_type === "personal" ? (
                                <>
                                  <Input
                                    disabled
                                    type="email"
                                    name="email"
                                    placeholder="Enter Email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    invalid={
                                      formik.touched.email &&
                                      formik.errors.email
                                        ? true
                                        : false
                                    }
                                  />
                                  {formik.touched.email &&
                                  formik.errors.email ? (
                                    <FormFeedback type="invalid">
                                      <div>{formik.errors.email}</div>
                                    </FormFeedback>
                                  ) : null}
                                </>
                              ) : (
                                <div
                                  type="email"
                                  className="form-control fw-bold border-0 bg-light"
                                  id="emailInput"
                                >
                                  {profileData?.data?.email || "-"}
                                </div>
                              )}
                            </div>
                          </Col>



                          {update_type === "personal" ? (
                            <></>
                          ) : (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="phonenumberInput"
                                  className="form-label"
                                >
                                  Organization ID
                                </Label>
                                <div
                                  type="text"
                                  className="form-control fw-bold border-0 bg-light"
                                  id="phonenumberInput"
                                >
                                  {profileData?.data?.organizationId || "-"}
                                </div>
                              </div>
                            </Col>
                          )}


                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="mobilenumberInput"
                                className="form-label"
                              >
                                Mobile Number
                              </Label>
                              {update_type === "personal" ? (
                                <>
                                  <Input
                                    disabled
                                    type="tel"
                                    name="mobileNumber"
                                    placeholder="Enter Contact No..."
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.mobileNumber}
                                    invalid={
                                      formik.touched.mobileNumber &&
                                      formik.errors.mobileNumber
                                        ? true
                                        : false
                                    }
                                  />
                                  {formik.touched.mobileNumber &&
                                  formik.errors.mobileNumber ? (
                                    <FormFeedback type="invalid">
                                      <div>{formik.errors.mobileNumber}</div>
                                    </FormFeedback>
                                  ) : null}
                                </>
                              ) : (
                                <div
                                  type="text"
                                  className="form-control fw-bold border-0 bg-light"
                                  id="mobilenumberInput"
                                >
                                  {profileData?.data?.mobileNumber || "-"}
                                </div>
                              )}
                            </div>
                          </Col>


                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? '' : <Label
                                  htmlFor="roleInput"
                                  className="form-label"
                                >
                                  Role
                                </Label>}
                                {update_type === "personal" ? (
                                  ''
                                ) :
                                  (
                                    profileData?.data?.role.length > 0 ? profileData?.data?.role.map((roles) => {
                                      return (
                                        <>
                                          <div
                                            type="text"
                                            className="form-control fw-bold border-0 bg-light"
                                            id="roleInput"
                                          >
                                            {roles || "-"}
                                          </div>
                                        </>
                                      )
                                    }) : <>
                                      <div
                                        type="text"
                                        className="form-control fw-bold border-0 bg-light"
                                        id="roleInput"
                                      >
                                        -
                                      </div>
                                    </>
                                  )
                                }
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? '' : <Label
                                  htmlFor="teamInput"
                                  className="form-label"
                                >
                                  Team
                                </Label>}
                                {update_type === "personal" ? (
                                  ""
                                ) : (
                                  profileData?.data?.team.map((temas) => {
                                    return (
                                      <>
                                        <div
                                          type="text"
                                          className="form-control fw-bold border-0 bg-light"
                                          id="teamInput"
                                        >
                                          {temas || "-"}
                                        </div>
                                      </>
                                    )
                                  })
                                )

                                }
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? '' : <Label
                                  htmlFor="registeredNameInput"
                                  className="form-label"
                                >
                                  Registered Date
                                </Label>}
                                {update_type === "personal" ? (
                                  <></>
                                ) : (
                                  // <>
                                  //   <Input
                                  //     type="text"
                                  //     name="team"
                                  //     placeholder="Enter Team Name..."
                                  //     onChange={formik.handleChange}
                                  //     onBlur={formik.handleBlur}
                                  //     value={formik.values.team}
                                  //     invalid={
                                  //       formik.touched.team && formik.errors.team
                                  //         ? true
                                  //         : false
                                  //     }
                                  //   />
                                  //   {formik.touched.team && formik.errors.team ? (
                                  //     <FormFeedback type="invalid">
                                  //       <div>{formik.errors.team}</div>
                                  //     </FormFeedback>
                                  //   ) : null}
                                  // </>
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="registeredInput"
                                  >
                                    {moment(
                                      profileData?.data?.createdAt
                                    ).format("DD  MMM  YYYY") || "-"}
                                  </div>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? '' : <Label
                                  htmlFor="deviceNameInput"
                                  className="form-label"
                                >
                                  Device Name
                                </Label>}
                                {update_type === "personal" ? (
                                  <></>
                                ) : (
                                  // <>
                                  //   <Input
                                  //     type="text"
                                  //     name="team"
                                  //     placeholder="Enter Team Name..."
                                  //     onChange={formik.handleChange}
                                  //     onBlur={formik.handleBlur}
                                  //     value={formik.values.team}
                                  //     invalid={
                                  //       formik.touched.team && formik.errors.team
                                  //         ? true
                                  //         : false
                                  //     }
                                  //   />
                                  //   {formik.touched.team && formik.errors.team ? (
                                  //     <FormFeedback type="invalid">
                                  //       <div>{formik.errors.team}</div>
                                  //     </FormFeedback>
                                  //   ) : null}
                                  // </>
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="deviceNameInput"
                                  >
                                    {profileData?.data?.deviceName || "-"}
                                  </div>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? '' : <Label
                                  htmlFor="deviceNameInput"
                                  className="form-label"
                                >
                                  App Version
                                </Label>}
                                {update_type === "personal" ? (
                                  <></>
                                ) : (
                                  // <>
                                  //   <Input
                                  //     type="text"
                                  //     name="team"
                                  //     placeholder="Enter Team Name..."
                                  //     onChange={formik.handleChange}
                                  //     onBlur={formik.handleBlur}
                                  //     value={formik.values.team}
                                  //     invalid={
                                  //       formik.touched.team && formik.errors.team
                                  //         ? true
                                  //         : false
                                  //     }
                                  //   />
                                  //   {formik.touched.team && formik.errors.team ? (
                                  //     <FormFeedback type="invalid">
                                  //       <div>{formik.errors.team}</div>
                                  //     </FormFeedback>
                                  //   ) : null}
                                  // </>
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="versionInput"
                                  >
                                    {profileData?.data?.appVersion || "-"}
                                  </div>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? '' : <Label
                                  htmlFor="callrecordingInput"
                                  className="form-label"
                                >
                                  Call Recording Sync
                                </Label>}
                                {update_type === "personal" ? (
                                  <></>
                                ) : (
                                  // <>
                                  //   <Input
                                  //     type="text"
                                  //     name="team"
                                  //     placeholder="Enter Team Name..."
                                  //     onChange={formik.handleChange}
                                  //     onBlur={formik.handleBlur}
                                  //     value={formik.values.team}
                                  //     invalid={
                                  //       formik.touched.team && formik.errors.team
                                  //         ? true
                                  //         : false
                                  //     }
                                  //   />
                                  //   {formik.touched.team && formik.errors.team ? (
                                  //     <FormFeedback type="invalid">
                                  //       <div>{formik.errors.team}</div>
                                  //     </FormFeedback>
                                  //   ) : null}
                                  // </>
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="callrecordingInput"
                                  >
                                    {profileData?.timestap || "-"}
                                  </div>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            <Col lg={6}>
                              <div className="mb-3">
                                {update_type === "personal" ? '' : <Label
                                  htmlFor="lastcallrecordingInput"
                                  className="form-label"
                                >
                                  Last Call Sync
                                </Label>}
                                {update_type === "personal" ? (
                                  <></>
                                ) : (
                                  // <>
                                  //   <Input
                                  //     type="text"
                                  //     name="team"
                                  //     placeholder="Enter Team Name..."
                                  //     onChange={formik.handleChange}
                                  //     onBlur={formik.handleBlur}
                                  //     value={formik.values.team}
                                  //     invalid={
                                  //       formik.touched.team && formik.errors.team
                                  //         ? true
                                  //         : false
                                  //     }
                                  //   />
                                  //   {formik.touched.team && formik.errors.team ? (
                                  //     <FormFeedback type="invalid">
                                  //       <div>{formik.errors.team}</div>
                                  //     </FormFeedback>
                                  //   ) : null}
                                  // </>
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="lastcallrecordingInput"
                                  >
                                    {profileData?.lastcallsync || "-"}
                                  </div>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label htmlFor="pincode" className="form-label">
                                  Pin Code
                                </Label>
                                {update_type === "personal" ? (
                                  <>
                                    <Input
                                      type="tel"
                                      name="pincode"
                                      placeholder="Enter postal No..."
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.pincode}
                                      invalid={
                                        formik.touched.pincode &&
                                        formik.errors.pincode
                                          ? true
                                          : false
                                      }
                                    />
                                    {formik.touched.pincode &&
                                    formik.errors.pincode ? (
                                      <FormFeedback type="invalid">
                                        <div>{formik.errors.pincode}</div>
                                      </FormFeedback>
                                    ) : null}
                                  </>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="pincodeInput"
                                  >
                                    {profileData?.data?.pincode || "-"}
                                  </div>
                                )}
                              </div>
                            </Col>
                          )}

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="IndustryInput"
                                  className="form-label"
                                >
                                  Industry Type
                                </Label>
                                {update_type === "personal" ? (
                                  <>
                                    <select
                                      id="industryType"
                                      className="form-select"
                                    >
                                      <i className="bi bi-chevron-down"></i>
                                      <option selected value={industryType}>
                                        {industryType}
                                      </option>

                                      {(
                                        industryTypeData?.industryType || []
                                      )?.map((ele, index) => {
                                        if (industryType === ele) {
                                          return null;
                                        }
                                        return (
                                          <>
                                            <option key={ele} value={ele}>
                                              {ele}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="Industryinput"
                                  >
                                    {profileData?.data?.industryType}
                                  </div>
                                )}
                              </div>
                            </Col>
                          )}

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="stateinput"
                                  className="form-label"
                                >
                                  State
                                </Label>
                                {update_type === "personal" ? (
                                  <>
                                    <Input
                                      type="text"
                                      name="stateData"
                                      placeholder="Enter State Name..."
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.stateData}
                                      invalid={
                                        formik.touched.stateData &&
                                        formik.errors.stateData
                                          ? true
                                          : false
                                      }
                                    />
                                    {formik.touched.stateData &&
                                    formik.errors.stateData ? (
                                      <FormFeedback type="invalid">
                                        <div>{formik.errors.stateData}</div>
                                      </FormFeedback>
                                    ) : null}
                                  </>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="state"
                                  >
                                    {profileData?.data?.state}
                                  </div>
                                )}
                              </div>
                            </Col>
                          )}

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="cityInput"
                                  className="form-label"
                                >
                                  City
                                </Label>
                                {update_type === "personal" ? (
                                  <>
                                    <Input
                                      type="text"
                                      name="city"
                                      placeholder="Enter City Name..."
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.city}
                                      invalid={
                                        formik.touched.city &&
                                        formik.errors.city
                                          ? true
                                          : false
                                      }
                                    />
                                    {formik.touched.city &&
                                    formik.errors.city ? (
                                      <FormFeedback type="invalid">
                                        <div>{formik.errors.city}</div>
                                      </FormFeedback>
                                    ) : null}
                                  </>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="cityInput"
                                  >
                                    {profileData?.data?.city}
                                  </div>
                                )}
                              </div>
                            </Col>
                          )}

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <Col lg={2}>
                              <div className="mb-3">
                                {update_type === "personal" ? (
                                  <Label
                                    htmlFor="zipcodeInput"
                                    className="form-label"
                                  >
                                    Select Country Code
                                  </Label>
                                ) : (
                                  <Label
                                    htmlFor="zipcodeInput"
                                    className="form-label"
                                  >
                                    Country Code
                                  </Label>
                                )}
                                {update_type === "personal" ? (
                                  <>
                                    <select
                                      onChange={handleCountryCode}
                                      className="form-select"
                                      name="countrycode"
                                    >
                                      <option selected value={code}>
                                        {code}
                                      </option>
                                      {industryTypeData?.countryList.map(
                                        (ele, key) => {
                                          if (code === ele?.code) {
                                            return null;
                                          }
                                          return (
                                            <>
                                              <option
                                                key={key}
                                                value={ele?.code}
                                              >
                                                {ele?.code}
                                              </option>
                                            </>
                                          );
                                        }
                                      )}
                                    </select>
                                  </>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    minLength="5"
                                    maxLength="6"
                                    id="zipcodeInput"
                                  >
                                    {profileData?.data?.countryCode}
                                  </div>
                                )}
                              </div>
                            </Col>
                          )}

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <Col lg={4}>
                              <div className="mb-3">
                                {update_type === "personal" ? (
                                  <Label
                                    htmlFor="country"
                                    className="form-label"
                                  >
                                    Select Country
                                  </Label>
                                ) : (
                                  <Label
                                    htmlFor="country"
                                    className="form-label"
                                  >
                                    Country{" "}
                                  </Label>
                                )}
                                {update_type === "personal" ? (
                                  <>
                                    <select
                                      className="form-select"
                                      onChange={handleCountry}
                                      name="country"
                                    >
                                      <option selected value={country}>
                                        {country}
                                      </option>
                                      {industryTypeData?.countryList.map(
                                        (ele, key) => {
                                          if (country === ele?.name) {
                                            return null;
                                          }
                                          return (
                                            <option key={key} value={ele?.name}>
                                              {ele?.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="countryInput"
                                  >
                                    {profileData?.data?.country}
                                  </div>
                                )}
                              </div>
                            </Col>
                          )}

                          {storedValue === "employee" ? (
                            ""
                          ) : (
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="cityInput"
                                  className="form-label"
                                >
                                  Website Url
                                </Label>
                                {update_type === "personal" ? (
                                  <>
                                    <Input
                                      type="text"
                                      className="form-label"
                                      name="website"
                                      value={websites}
                                      onChange={handleWebInputChange}
                                    />
                                  </>
                                ) : (
                                  <div
                                    type="text"
                                    className="form-control fw-bold border-0 bg-light"
                                    id="webInput"
                                  >
                                    {profileData?.data?.website
                                      ? profileData?.data?.website
                                      : "www.salesNinja.com"}
                                  </div>
                                )}
                              </div>
                            </Col>
                          )}

                          {update_type === "personal" ? (
                            <Col lg={12}>
                              <div className="hstack gap-2 justify-content-end">
                                <Button
                                  type="submit"
                                  className="btn btn-danger"
                                >
                                  Update
                                </Button>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                      </Form>
                    </TabPane>
                    <ChangePassword />

                    <TabPane tabId="3">
                      <form>
                        <div id="newlink">
                          <div id="1">
                            <Row>
                              <Col lg={12}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="jobTitle"
                                    className="form-label"
                                  >
                                    Job Title
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="jobTitle"
                                    placeholder="Job title"
                                    defaultValue="Lead Designer / Developer"
                                  />
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="companyName"
                                    className="form-label"
                                  >
                                    Company Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="companyName"
                                    placeholder="Company name"
                                    defaultValue="Themesbrand"
                                  />
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="mb-3">
                                  <label
                                    htmlFor="experienceYear"
                                    className="form-label"
                                  >
                                    Experience Years
                                  </label>
                                  <Row>
                                    <Col lg={5}>
                                      <select
                                        className="form-control"
                                        data-choices
                                        data-choices-search-false
                                        name="experienceYear"
                                        id="experienceYear"
                                      >
                                        <option defaultValue="">
                                          Select years
                                        </option>
                                        <option value="Choice 1">2001</option>
                                        <option value="Choice 2">2002</option>
                                        <option value="Choice 3">2003</option>
                                        <option value="Choice 4">2004</option>
                                        <option value="Choice 5">2005</option>
                                        <option value="Choice 6">2006</option>
                                        <option value="Choice 7">2007</option>
                                        <option value="Choice 8">2008</option>
                                        <option value="Choice 9">2009</option>
                                        <option value="Choice 10">2010</option>
                                        <option value="Choice 11">2011</option>
                                        <option value="Choice 12">2012</option>
                                        <option value="Choice 13">2013</option>
                                        <option value="Choice 14">2014</option>
                                        <option value="Choice 15">2015</option>
                                        <option value="Choice 16">2016</option>
                                        <option value="Choice 17">2017</option>
                                        <option value="Choice 18">2018</option>
                                        <option value="Choice 19">2019</option>
                                        <option value="Choice 20">2020</option>
                                        <option value="Choice 21">2021</option>
                                        <option value="Choice 22">2022</option>
                                      </select>
                                    </Col>

                                    <div className="col-auto align-self-center">
                                      to
                                    </div>

                                    <Col lg={5}>
                                      <select
                                        className="form-control"
                                        data-choices
                                        data-choices-search-false
                                        name="choices-single-default2"
                                      >
                                        <option defaultValue="">
                                          Select years
                                        </option>
                                        <option value="Choice 1">2001</option>
                                        <option value="Choice 2">2002</option>
                                        <option value="Choice 3">2003</option>
                                        <option value="Choice 4">2004</option>
                                        <option value="Choice 5">2005</option>
                                        <option value="Choice 6">2006</option>
                                        <option value="Choice 7">2007</option>
                                        <option value="Choice 8">2008</option>
                                        <option value="Choice 9">2009</option>
                                        <option value="Choice 10">2010</option>
                                        <option value="Choice 11">2011</option>
                                        <option value="Choice 12">2012</option>
                                        <option value="Choice 13">2013</option>
                                        <option value="Choice 14">2014</option>
                                        <option value="Choice 15">2015</option>
                                        <option value="Choice 16">2016</option>
                                        <option value="Choice 17">2017</option>
                                        <option value="Choice 18">2018</option>
                                        <option value="Choice 19">2019</option>
                                        <option value="Choice 20">2020</option>
                                        <option value="Choice 21">2021</option>
                                        <option value="Choice 22">2022</option>
                                      </select>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col lg={12}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="jobDescription"
                                    className="form-label"
                                  >
                                    Job Description
                                  </Label>
                                  {/* <textarea className="form-control" id="jobDescription"
                                                            defaultValue=""
                                                                rows="3"
                                                                placeholder="Enter description">You always want to make sure that your fonts work well together and try to limit the number of fonts you use to three or less. Experiment and play around with the fonts that you already have in the software you're working with reputable font websites. </textarea> */}
                                </div>
                              </Col>

                              <div className="hstack gap-2 justify-content-end">
                                <Link className="btn btn-success" to="#">
                                  Delete
                                </Link>
                              </div>
                            </Row>
                          </div>
                        </div>
                        <div id="newForm" style={{ display: "none" }}></div>

                        <Col lg={12}>
                          <div className="hstack gap-2">
                            <button type="submit" className="btn btn-success">
                              Update
                            </button>
                            <Link to="#" className="btn btn-primary">
                              Add New
                            </Link>
                          </div>
                        </Col>
                      </form>
                    </TabPane>

                    <TabPane tabId="4">
                      <div className="mb-4 pb-2">
                        <h5 className="card-title text-decoration-underline mb-3">
                          Security:
                        </h5>
                        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                          <div className="flex-grow-1">
                            <h6 className="fs-15 mb-1">
                              Two-factor Authentication
                            </h6>
                            <p className="text-muted">
                              Two-factor authentication is an enhanced security
                              meansur. Once enabled, you'll be required to give
                              two types of identification when you log into
                              Google Authentication and SMS are Supported.
                            </p>
                          </div>
                          <div className="flex-shrink-0 ms-sm-3">
                            <Link to="#" className="btn btn-sm btn-primary">
                              Enable Two-facor Authentication
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                          <div className="flex-grow-1">
                            <h6 className="fs-15 mb-1">
                              Secondary Verification
                            </h6>
                            <p className="text-muted">
                              The first factor is a password and the second
                              commonly includes a text with a code sent to your
                              smartphone, or biometrics using your fingerprint,
                              face, or retina.
                            </p>
                          </div>
                          <div className="flex-shrink-0 ms-sm-3">
                            <Link to="#" className="btn btn-sm btn-primary">
                              Set up secondary method
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0 mt-2">
                          <div className="flex-grow-1">
                            <h6 className="fs-15 mb-1">Backup Codes</h6>
                            <p className="text-muted mb-sm-0">
                              A backup code is automatically generated for you
                              when you turn on two-factor authentication through
                              your iOS or Android Twitter app. You can also
                              generate a backup code on twitter.com.
                            </p>
                          </div>
                          <div className="flex-shrink-0 ms-sm-3">
                            <Link to="#" className="btn btn-sm btn-primary">
                              Generate backup codes
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <h5 className="card-title text-decoration-underline mb-3">
                          Application Notifications:
                        </h5>
                        <ul className="list-unstyled mb-0">
                          <li className="d-flex">
                            <div className="flex-grow-1">
                              <label
                                htmlFor="directMessage"
                                className="form-check-label fs-15"
                              >
                                Direct messages
                              </label>
                              <p className="text-muted">
                                Messages from people you follow
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="directMessage"
                                  defaultChecked
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-15"
                                htmlFor="desktopNotification"
                              >
                                Show desktop notifications
                              </Label>
                              <p className="text-muted">
                                Choose the option you want as your default
                                setting. Block a site: Next to "Not allowed to
                                send notifications," click Add.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="desktopNotification"
                                  defaultChecked
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-15"
                                htmlFor="emailNotification"
                              >
                                Show email notifications
                              </Label>
                              <p className="text-muted">
                                {" "}
                                Under Settings, choose Notifications. Under
                                Select an account, choose the account to enable
                                notifications for.{" "}
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="emailNotification"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-15"
                                htmlFor="chatNotification"
                              >
                                Show chat notifications
                              </Label>
                              <p className="text-muted">
                                To prevent duplicate mobile notifications from
                                the Gmail and Chat apps, in settings, turn off
                                Chat notifications.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="chatNotification"
                                />
                              </div>
                            </div>
                          </li>
                          <li className="d-flex mt-2">
                            <div className="flex-grow-1">
                              <Label
                                className="form-check-label fs-15"
                                htmlFor="purchaesNotification"
                              >
                                Show purchase notifications
                              </Label>
                              <p className="text-muted">
                                Get real-time purchase alerts to protect
                                yourself from fraudulent charges.
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="form-check form-switch">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="purchaesNotification"
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <h5 className="card-title text-decoration-underline mb-3">
                          Delete This Account:
                        </h5>
                        <p className="text-muted">
                          Go to the Data & Privacy section of your profile
                          Account. Scroll to "Your data & privacy options."
                          Delete your Profile Account. Follow the instructions
                          to delete your account :
                        </p>
                        <div>
                          <Input
                            type="password"
                            className="form-control"
                            id="passwordInput"
                            placeholder="Enter your password"
                            defaultValue="make@321654987"
                            style={{ maxWidth: "265px" }}
                          />
                        </div>
                        <div className="hstack gap-2 mt-3">
                          <Link to="#" className="btn btn-soft-danger">
                            Close & Delete This Account
                          </Link>
                          <Link to="#" className="btn btn-light">
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
