import { Grid, _ } from "gridjs-react";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Tooltip,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { EMPLOYEE_REPORT } from "../../helpers/url_helper";
import { html } from "gridjs";
import moment from "moment";
import EmployeeSelect from "../../Components/Common/EmployeeSelect";
import { DatePickerWithoutTimes } from "../../Components/Common/Flatpicker";
import { formatDate } from "../../Components/Common/FormDateTime";
import { APIClient } from "../../helpers/api_helper";
import {
  getPreviousDate,
  setCurrentDate,
} from "../../Components/Common/DefaultDateSet";
import { HandleErrorMessage } from "../../Components/Common/HandleErrorMessage";
import Loader from "../../Components/Common/Loader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import SubExpired from "../../Components/Common/subExpired";
import { useProfileData } from "../../Components/Hooks/UserHooks";
const api = new APIClient();

function EmployeeReport() {
  document.title = "Employee Report";
  const navigate = useNavigate();
  const { profileData } = useProfileData()
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const viewReportPermission = permissionType?.includes("VIEW_REPORT");
  const loginType = JSON.parse(localStorage.getItem("logintype"))

  const [remainingDays, setRemainingDays] = useState(null);
  const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const previewsDate = getPreviousDate();
  const currentDate = setCurrentDate();
  const [EmployeeData, setEmployeeData] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(previewsDate);
  const [selectedToDate, setSelectedToDate] = useState(currentDate);
  // employee select
  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);
  const [employeeRportData, setEmployeeReportData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [filterData, setFilteredData] = useState([]);
  const [shouldFetchData, setShouldFetchData] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);

  // flatPicker Date
  const formattedStartDate = formatDate(selectedFromDate);
  const formattedEndDate = formatDate(selectedToDate);


  const column = [

    {
      id: "srNo",
      name: html(
        '<div style="text-align:center;font-size:medium;font-weight: bold;">SN.</div>'
      ),
      formatter: (cell) =>
        html(`<div style="text-align:center;">${cell}</div>`),
      width: '5%',
    },

    {
      id: "date",
      name: html(
        '<div style="text-align:center; font-size:medium;font-weight: bold; color:#333" placement="top" isOpen={tooltipOpen} target={"Tooltip-"+id} toggle={toggle}>Date</div>'
      ),
      formatter: (cell) =>
        html(`<div style="color:#000" class="text-center">${cell}</div>`),
    },
    {
      id: "tCalls",
      name: html(
        '<div style="text-align:center;font-size:medium;font-weight: bold;">Total<br/><span> Calls </span></div>'
      ),
      formatter: (cell) =>
        html(
          `<div style="border-radius: 14px;display: block;height: 20px;line-height: 20px;text-align: center;width: 50px;background:#dedef38f;">${cell}</div>`
        ),
    },
    {
      id: "tDuration",
      name: html(
        '<div style="text-align:center;font-size:medium;font-weight: bold;">Total<br/><span> Duration</span></div>'
      ),
      formatter: (cell) => html(`<div class="text-center">${cell}</div>`),
    },
    {
      id: "uniqueClient",
      name: html(
        '<div style="text-align:center;font-size:medium;font-weight: bold;"><span>Unique<br/>Call</span><br/> <div class="tooltip-container" id="uniqu" style="text-align:center; font-size:medium; font-weight: bold;"><div class="btn-tooltip"><i class="mdi mdi-alert-circle-outline" data-tooltip-id="my-tooltip-1"></i><div class="tooltip-text"><p>Shows unique numbers contacted, tracking the exact number of clients reached by your employees.</p></div></div></div></div>'
      ),
      formatter: (cell) => html(`<div class="text-center">${cell}</div>`),
    },
    {
      id: "connectedCalls",
      name: html(
        // '<div style="text-align:center;font-size:medium;font-weight: bold;">Connected<br/><span> Calls</span><br/><i style="color: #FDA403;" class="mdi mdi-alert-circle-outline"></i></div>'
        '<div style="text-align:center;font-size:medium;font-weight: bold;"><span>Connected<br/>Calls</span><br/> <div class="tooltip-container" id="connect" style="text-align:center; font-size:medium; font-weight: bold;"><div class="btn-tooltip"><i class="mdi mdi-alert-circle-outline"></i><div class="tooltip-text"><p>This list shows the number of calls that were connected and spoken. It helps you track detailed interactions with your clients!</p></div></div></div></div>'
      ),
      formatter: (cell) =>
        html(
          `<div style="border-radius: 14px;display: block;height: 20px;line-height: 20px;text-align: center;width: 50px;background:#265c2e2b;">${cell}</div>`
        ),
    },
    {
      id: "totalIncomingCall",
      name: html(
        '<div style="text-align:center; color: #3cb371; font-size:medium;font-weight: bold;"> <i class="mdi mdi-phone-incoming text-success"></i> <span>Incoming</span></div>'
      ),
      columns: [
        {
          id: "inTotalCall",
          name: html(
            '<div style="text-align:center;font-size:medium;font-weight: bold;">Total<br/><span> Call</span></div>'
          ),
          formatter: (cell) =>
            html(
              `<div  style=" background: #dedef38f !important;border-radius: 14px;display: block;height: 20px;line-height: 20px;text-align: center;width: 50px;">${cell}</div>`
            ),
        },
        {
          id: "inConnectedCall",
          name: html(
            '<div style="text-align:center;font-size:medium;font-weight: bold;">Connected<br/><span>Call</span></div>'
          ),
          formatter: (cell) =>
            html(
              `<div  style="border-radius: 14px;display: block;height: 20px;line-height: 20px;text-align: center;width: 50px;background:#265c2e2b;">${cell}</div>`
            ),
        },
        {
          id: "indurationCall",
          name: html(
            '<div style="text-align:center;font-size:medium;font-weight: bold;">Total<br/><span>Duration</span></div>'
          ),
          formatter: (cell) =>
            html(`<div  style="text-align:center;">${cell}</div>`),
        },
      ],
    },
    {
      id: "totalOutgoingCall",
      name: html(
        '<div style="text-align:center; color: #29badb; font-size:medium;font-weight: bold;"> <i class="mdi mdi-phone-outgoing text-info"></i> <span>Outgoing</span></div>'
      ),
      columns: [
        {
          id: "outTotalCall",
          name: html(
            '<div style="text-align:center;font-size:medium;font-weight: bold;">Total<br/><span> Call</span></div>'
          ),
          formatter: (cell) =>
            html(
              `<div  style="background:#dedef38f !important;border-radius: 14px;display: block;height: 20px;line-height: 20px;text-align: center;width: 50px;;">${cell}</div>`
            ),
        },
        {
          id: "outTotalConnected",
          name: html(
            '<div style="text-align:center;font-size:medium;font-weight: bold;">Connected<br/><span> Calls</span></div>'
          ),
          formatter: (cell) =>
            html(
              `<div  style="border-radius: 14px;display: block;height: 20px;line-height: 20px;text-align: center;width: 50px;background:#265c2e2b;">${cell}</div>`
            ),
        },
        {
          id: "outTotalduration",
          name: html(
            '<div style="text-align:center;font-size:medium;font-weight: bold;">Total<br/><span>Duration</span></div>'
          ),
          formatter: (cell) =>
            html(`<div  style="text-align:center;">${cell}</div>`),
        },
      ],
    },
    {
      id: "missed",
      name: html(
        '<div style="text-align:center;font-size:medium;font-weight: bold;color: #FF0000;"><i class="mdi mdi-arrow-left-bottom"></i><br/> <span>Missed</span></div>'
      ),
      formatter: (cell) =>
        html(
          `<div style="border-radius: 14px;display: block;height: 20px;line-height: 20px;text-align: center;width: 50px;background:#f171718a;">${cell}</div>`
        ),
    },
    {
      id: "rejected",
      name: html(
        '<div style="text-align:center;font-size:medium;font-weight: bold;color: #db62ef;"><i class="ri-indeterminate-circle-line"></i><br/> <span>Rejected</span></div>'
      ),
      formatter: (cell) =>
        html(
          `<div style="border-radius: 14px;display: block;height: 20px;line-height: 20px;text-align: center;width: 50px;background:#eca8f7a3">${cell}</div>`
        ),
    },
    {
      id: "naverAttend",
      name: html(
        // '<div style="text-align:center;font-size:medium;font-weight: bold;">Never<br/><span> Attended</span></div>'
        '<div style="text-align:center;font-size:medium;font-weight: bold;"><span>Never<br/>Attended</span><br/> <div class="tooltip-container" style="text-align:center; font-size:medium; font-weight: bold;"><div class="btn-tooltip"><i class="mdi mdi-alert-circle-outline"></i><div class="tooltip-text"><p>This list shows calls you ve missed and haven t returned. These numbers will stay here until you reconnect, ensuring you never miss a client!</p></div></div></div></div>'
      ),
      formatter: (cell) => html(`<div class="text-center">${cell}</div>`),
    },
    {
      id: "notPickup",
      name: html(
        // '<div style="text-align:center;font-size:medium;font-weight: bold;">Not <br/> Pickup<br/><span>by <br/>Client</span></div>'
        '<div class="employe-report-not-pickup-colum-tbl" style="text-align:center;font-size:medium;font-weight: bold;"><span>Not<br/>Picked<br/>Up by<br/>Client</span><br/> <div class="tooltip-container" style="text-align:center; font-size:medium; font-weight: bold;"><div class="btn-tooltip"><i class="mdi mdi-alert-circle-outline"></i><div class="tooltip-text"><p>Not just outgoing calls. Shows dialed but unconnected calls. Numbers stay until you connect, ensuring no missed contacts.</p></div></div></div></div>'
      ),
      formatter: (cell) =>
        html(
          `<div style="border-radius: 14px;display: block;height: 20px;line-height: 20px;text-align: center;width: 50px;background:#f5d17f8f;">${cell}</div>`
        ),

    },
  ];


  useEffect(() => {
    const calculateRemainingDays = () => {
      if (profileData?.subscriptionEndDate && profileData?.subscriptionStartDate) {
        const endDate = new Date(profileData?.subscriptionEndDate);
        const startDate = new Date(profileData?.subscriptionStartDate);
        const currentDate = new Date();

        const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
        const remainingDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24))
        setRemainingDays(remainingDays > 0 ? remainingDays : 0);
        const interval = setInterval(() => {
          const newCurrentDate = new Date();
          const newRemainingDays = Math.ceil((endDate - newCurrentDate) / (1000 * 60 * 60 * 24));
          setRemainingDays(newRemainingDays);
        }, 1000 * 60 * 60 * 24);

        return () => clearInterval(interval);
      } else {
        setRemainingDays(null);
      }
    };

    calculateRemainingDays();
  }, [profileData]);


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // From Date
  const handleFromDateChange = (dateStr) => {
    setSelectedFromDate(dateStr);
  };

  // To date
  const handleToDateChange = (dateStr) => {
    setSelectedToDate(dateStr);
  };


  const fetchSummaryData = (id) => {
    getEmployeeReportData('', id)
  };


  useEffect(() => {
    if (loginType === 'employee') {
      fetchSummaryData()
    }
    if (shouldFetchData && EmployeeData.length > 0 && !dataFetched) {
      const defaultId = EmployeeData?.map((option) => option?.id) || [];
      fetchSummaryData(defaultId);
      setDataFetched(true);
    }
  }, [shouldFetchData, EmployeeData, dataFetched]);


  // useEffect(() => {
  //   if (shouldFetchData && EmployeeData.length > 0 && !dataFetched) {
  //     const defaultId = EmployeeData?.map((option) => option?.id) || [];
  //     getEmployeeReportData('', defaultId);
  //   }
  // }, [EmployeeData]);

  const handleSubmit = () => {
    const employeeid = selectedEmployeeOptions.map((userid) => userid?.id);
    getEmployeeReportData(employeeid, '')
    setShouldFetchData(false);
    setDataFetched(false);
  }


  const getEmployeeReportData = async (employeeid = [], defaultId = []) => {
    setIsLoading(true);
    try {

      let data = {};
      if (defaultId.length > 0) {
        data = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          employeeIds: defaultId,
        };
      } else {
        data = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          employeeIds: employeeid
        };
      }
      const response = await api.create(EMPLOYEE_REPORT, data);
      if (response && response?.success === true) {
        setIsLoading(false);
        const transformedData = response.data.map((data, index) => {
          setIsLoading(false);
          return {
            srNo: index + 1,
            date: moment(data?._id?.date).format("DD MMM YYYY") || "-",
            tCalls: data?.totalCall || "-",
            tDuration: new Date(data.totalDuration * 1000)
              .toISOString()
              .slice(11, 19) || "-",
            uniqueClient: data?.uniqueClient || "-",
            connectedCalls: data?.connectedCalls || "-",
            inTotalCall: data?.totallIncomingCall || "-",
            inConnectedCall: data?.totallIncomingConnectedCall || "-",
            indurationCall: new Date(data?.totallIncomingCallDuration * 1000)
              .toISOString()
              .slice(11, 19)
              || "-",
            outTotalCall: data?.totallOutgoingCall || "-",
            outTotalConnected: data?.totallOutgoingConnectedCall || "-",
            outTotalduration: new Date(data?.totallOutgoingCallDuration * 1000)
              .toISOString()
              .slice(11, 19) || "-",
            missed: data?.totallMissedCall || "-",
            rejected: data?.totalRejectedCall || "-",
            notPickup: data?.totalNotPickedUpByClient || "-",
            naverAttend: data?.neverAttended || "-",
          };
        });
        setEmployeeReportData(transformedData);
      }
    } catch (error) {
      if (!remainingDays === 0) {
        HandleErrorMessage(error);
      }
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    const filtered = (employeeRportData || []).filter((item) =>
      Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );

    setFilteredData(filtered);
  }, [employeeRportData, searchQuery]);



  //reset in field
  const resetInputHistory = () => {
    setSelectedToDate("");
    setSelectedFromDate("");
    setSelectedEmployeeOptions([]);
  };


  const handleClose = () => {
    setIsExpiredModalOpen(false);
    navigate("/planPackage");
  };

  return (
    <React.Fragment>
      {(remainingDays === 0 && isExpiredModalOpen) && <SubExpired onClose={handleClose} profileData={profileData?.selectedPlanDetails?.planName} remainingDays={remainingDays} />}
      <div className="page-content sn-employee-report-page-main">
        <Container fluid>
          <ReactTooltip
            id="my-tooltip-1"
            place="top"
            className="custom-tooltip"
          ></ReactTooltip>
          <BreadCrumb title="Employee Report" pageTitle="Employee Report" />
          <Row style={{ marginBottom: "100px" }}>
            <Col lg={12}>
              <Card className="h-100">
                <CardBody className="sn-header-select-employess-home sn-top-inputs-heading sn-employess-tble-main-issue sn-employee-report-tble-respose--">
                  <div className="row">
                    {((viewReportPermission && loginType === 'employee') || loginType === 'organization') && <div className="col-md-3 col-sm-12 sn-select-employees-box">
                      <EmployeeSelect
                        EmployeeDefault={EmployeeData}
                        setEmployeeDefault={setEmployeeData}
                        selectedEmployeeOptions={selectedEmployeeOptions}
                        setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                        labelContent="Select Employee"
                      />
                    </div>}

                    <div className="col-md-3 col-sm-12">
                      <DatePickerWithoutTimes
                        labelContent="From Date"
                        getDate={selectedFromDate}
                        onChange={handleFromDateChange}
                      />
                    </div>

                    <div className="col-md-3 col-sm-12">
                      <DatePickerWithoutTimes
                        labelContent="To Date"
                        getDate={selectedToDate}
                        onChange={handleToDateChange}
                      />
                    </div>

                    <div className="col-md-3 col-sm-12">
                      <FormGroup className="mt-2 mb-4">
                        <Label for="exampleSelect"></Label>
                        <div
                          id="exampleSelect"
                          className="sn-call-apply-btn-reset"
                        >
                          <Button
                            id="exampleSelect"
                            type="submit"
                            color="danger"
                            className=""
                            onClick={handleSubmit}
                            disabled={isLoading}
                          >
                            Apply
                          </Button>
                          <Button
                            className=""
                            style={{ marginLeft: "10px" }}
                            id="exampleSelect"
                            outline
                            color="danger"
                            onClick={resetInputHistory}
                            disabled={isLoading}
                          >
                            Reset
                          </Button>
                        </div>
                      </FormGroup>
                    </div>
                  </div>

                  <Row className="g-3 mt-0 sn-employeessReport-tble-in-out sn-employessreport-alert-mainnn">
                    {isLoading === true && <Loader />}
                    {isLoading === false && (
                      <>
                        {" "}
                        <Col xxl={2} md={4} className={`ms-auto mt-0 pt-0 ${employeeRportData && employeeRportData.length > 0 ? 'd-block' : 'd-none'}`}>
                        <Input
                        id="searchInput"
                        type="text"
                        placeholder="Search by keyword..."
                        value={searchQuery}
                       onChange={handleSearchChange}
                      />
                     </Col>
                        <Col className="employe-report-not-pickup-colum-tbl" lg={12}>
                          <Grid 
                            data={filterData}
                            columns={column}
                            sort={true}
                            fixedHeader={true}
                            pagination={{ enabled: true, limit: 10 }}
                            style={{ minWidth: "100%" }}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default EmployeeReport;







