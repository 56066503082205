import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { CLIENT_REPORT, } from "../../helpers/url_helper";
import CommanDataTable from "../../Components/Common/DataTable";
import { DatePickerWithoutTimes } from "../../Components/Common/Flatpicker";
import SearchField from "../../Components/Common/SearchField";
import { formatDate } from "../../Components/Common/FormDateTime";
import { APIClient } from "../../helpers/api_helper";
import { getPreviousDate, setCurrentDate } from "../../Components/Common/DefaultDateSet";
import { HandleErrorMessage } from "../../Components/Common/HandleErrorMessage";
import moment from "moment";
import SubExpired from "../../Components/Common/subExpired";
import { useProfileData } from "../../Components/Hooks/UserHooks";
import { useNavigate } from "react-router-dom";
const api = new APIClient();

const ClientReport = () => {
    document.title = "Client Report";
    const navigate = useNavigate();
    const previewsDate = getPreviousDate()
    const currentDate = setCurrentDate()
    const { profileData } = useProfileData()
    const [isLoading, setIsLoading] = useState(true);
    const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(true);
    // date picker state
    const [selectedFromDate, setSelectedFromDate] = useState(previewsDate);
    const [selectedToDate, setSelectedToDate] = useState(currentDate);

    // search field state 
    const [searchValue, setSearchValue] = useState("");

    // Pagination state
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [clientReport, setClientReport] = useState([])

    //search field state
    const [query, setQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState('');

    const [remainingDays, setRemainingDays] = useState(null);

    // // flatPicker Date
    const formattedStartDate = formatDate(selectedFromDate);
    const formattedEndDate = formatDate(selectedToDate);


    // From Date
    const handleFromDateChange = (dateStr) => {
        setSelectedFromDate(dateStr);
    }

    // To date
    const handleToDateChange = (dateStr) => {
        setSelectedToDate(dateStr)
    }

    // search field function
    const handleSearchField = (e) => {
        setSearchValue(e.target.value)
    }


    const column = [
        // {
        //     name: <span className='font-weight-bold fs-13 text-center'>SN.</span>,
        //     selector: (row, index) => index + 1,  // Serial number logic
        //     width:'5%',
        //     cell: (row, index) => (
        //         <div className='mt-2 mb-2'>
        //             <h5 className='font-weight-bold fs-13 text-center'>{index + 1}</h5>
        //         </div>
        //     ),
        //     sortable: false,  // Serial number is usually not sortable
        // },
        {
            name: <span className="font-weight-bold fs-13"> SN.</span>,
            width: '5%',
            selector: (row, index) => (currentPage - 1) * perPage + index + 1,
            sortable: true, // This sets the width of the "Sr. No" column to 30px
        },

        {
            name: <span className='font-weight-bold fs-13 text-center' style={{ textTransform: 'capitalize' }}>Employee</span>,
            selector: row => row?.fullName,
            cell: row => (
                <div className='mt-2 mb-2'>
                    <h5 className='font-weight-bold fs-13' style={{ textTransform: 'capitalize' }}>{row?.fullName}</h5>
                    <h6 className='text-center- font-weight-bold fs-13'>{row?.mobileNumber}</h6>
                </div>
            ),
            sortable: true,
        },
        {
            name: <span>Client Name</span>,
            selector: row => row?.name,
            cell: row => (
                <div className='mt-2 mb-2'>
                    <h5 className='font-weight-bold fs-13' style={{ textTransform: 'capitalize' }}>{row?.name}</h5>
                    <h6 className='text-center- font-weight-bold fs-13'>{row?.phoneNumber}</h6>
                </div>
            ),
            sortable: true,
        },

        {
            name: <span className='font-weight-bold fs-13'>Date & Time</span>,
            selector: row => row?.date,
            cell: row => (
                <div className='mt-2 mb-2'>
                    <h5 className='font-weight-bold fs-13' style={{ textTransform: 'capitalize' }}>{row?.date}</h5>
                    <h6 className='text-center- font-weight-bold fs-13'>{row?.time}</h6>
                </div>
            ),
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Call Recording</span>,
            width: '350px',
            selector: row => (
                <>
                    {row?.recording ? <div style={{ display: 'inline-grid', marginBottom: '20px', marginTop: '10px' }}>
                        <span style={{ height: '10px' }}>{row?.duration}</span>
                        <audio style={{ height: '40px', marginTop: '15px' }} src={row?.recording} controls />
                    </div> :
                        <div style={{ display: 'inline-grid', marginBottom: '20px', marginTop: '10px' }}>
                            {row.type === 'OUTGOING' ? <span style={{ height: '10px' }}>{row?.duration}</span> : row.type === 'INCOMING' ? <span style={{ height: '10px' }}>{row?.duration}</span> : ''}
                            <span style={{ marginTop: '10px', color: 'warning' }}>Recording Not Found</span>
                        </div>}
                </>
            ),
        },

        {
            name: <span className='font-weight-bold fs-13'>Call Type</span>,
            selector: (cell) => {
                switch (cell.type) {
                    case "OUTGOING":
                        return <><i className="mdi mdi-phone-outgoing sn-out-going-text" /> <span className="badge badge-soft-info sn-out-going-call">  {cell.type}</span></>;
                    case "REJECTED":
                        return <><i className="mdi mdi-phone-cancel sn-reject-call-icontext" /><span className="badge badge-soft-warning"> {cell.type} </span></>;
                    case "MISSED":
                        return <><i className="mdi mdi-phone-missed sn-out-missed-text" /> <span className="ml-4 badge badge-soft-danger sn-out-missed-call"> {cell.type} </span></>;
                    case "INCOMING":
                        return <><i className="mdi mdi-phone-in-talk sn-out-incoming-text" /><span className="ml-4 badge badge-soft-success sn-out-incoming-call"> {cell.type} </span></>;
                    default:
                        return <span className="badge badge-soft-success"> {cell.type} </span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Notes</span>,
            selector: row => row.notes,
            // sortable: true
        },


    ];

    useEffect(() => {
        const calculateRemainingDays = () => {
            if (profileData?.subscriptionEndDate && profileData?.subscriptionStartDate) {
                const endDate = new Date(profileData?.subscriptionEndDate);
                const startDate = new Date(profileData?.subscriptionStartDate);
                const currentDate = new Date();

                const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
                const remainingDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24))
                setRemainingDays(remainingDays > 0 ? remainingDays : 0);
                const interval = setInterval(() => {
                    const newCurrentDate = new Date();
                    const newRemainingDays = Math.ceil((endDate - newCurrentDate) / (1000 * 60 * 60 * 24));
                    setRemainingDays(newRemainingDays);
                }, 1000 * 60 * 60 * 24);

                return () => clearInterval(interval);
            } else {
                setRemainingDays(null);
            }
        };

        calculateRemainingDays();
    }, [profileData]);

    // Debounce search query
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(query);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [query]);


    // Fetch data when debounced query, currentPage, or perPage changes
    useEffect(() => {
        const fetchData = async () => {
            if (debouncedQuery.length >= 3 || debouncedQuery.length === 0) {
                await clientgetReport(currentPage, perPage, debouncedQuery);
            }
        };

        fetchData();
    }, [debouncedQuery, currentPage, perPage]);



    // Search field handler
    const handleSearch = (e) => {
        setQuery(e.target.value);
    };


    // table data
    const handleSubmit = () => {
        clientgetReport(currentPage, perPage)
        setCurrentPage(1)
        setPerPage(10);
    }



    // Pagination handler
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };



    const clientgetReport = async (page, size = perPage, searchTerm = '') => {
        setIsLoading(true);
        const extractedNumber = searchValue.replace(/^\+91-/, '');
        try {
            let data = {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                clientPhoneNumberOrName: `${extractedNumber}`
            };
            const response = await api.create(`${CLIENT_REPORT}?pg=${page}&lm=${size}&query=${searchTerm}`, data);
            if (response && response?.success === true) {
                setIsLoading(false)
                const apiResponse = response.data;
                const clientReportType = (apiResponse?.paginatedResults || []).map((report, index) => {
                    const dateTimeString = report.dateTime
                    const date = new Date(dateTimeString);
                    const options = {
                        hour: '2-digit',
                        minute: '2-digit',
                        // second: '2-digit',
                        hour12: true,
                        timeZone: 'Asia/Kolkata'
                    };
                    const formatter = new Intl.DateTimeFormat('en-GB', options);
                    const istDateTimeString = formatter.format(date);
                    return {
                        date: dateTimeString ? moment(dateTimeString).format('DD MMM YYYY') : '-',
                        time: istDateTimeString || '-',
                        name: `${report.name || 'unknown'}`,
                        phoneNumber: `${report.phoneNumber || '-'}`,
                        type: `${report.type || '-'}`,
                        notes: `${report.note || '-'}`,
                        fullName: `${report.employeeDesription[0]?.fullName || 'unknown'}`,
                        mobileNumber: `${report.employeeDesription[0]?.mobileNumber || '-'}`,
                        duration: report?.duration ? new Date(report.duration * 1000).toISOString().slice(11, 19) : '-',
                        recording: report?.callRecording
                    }
                });
                setClientReport(clientReportType);
                setTotalRows(apiResponse?.totalCount);
            }
        } catch (error) {
            if (!remainingDays === 0) {
                HandleErrorMessage(error);
            }
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleClose = () => {
        setIsExpiredModalOpen(false);
        navigate("/planPackage");
    };

    return (
        <React.Fragment>
            {(remainingDays === 0 && isExpiredModalOpen) && <SubExpired onClose={handleClose} profileData={profileData?.selectedPlanDetails?.planName} remainingDays={remainingDays} />}
            <div className="page-content sn-client-report-main-page">
                <Container fluid>
                    <BreadCrumb title='Client Report' pageTitle="Client Report" />
                    <Row>
                        <Col lg={12}>
                            <Card className='h-100'>
                                {/* <CardHeader>
                                    <h4 className="card-title mb-0">Client Report</h4>
                                </CardHeader> */}
                                <CardBody>
                                    <Row className="sn-header-select-employess-home sn-top-inputs-heading-">
                                        <div className="col-md-3 col-sm-12">
                                            <DatePickerWithoutTimes onChange={handleFromDateChange} getDate={selectedFromDate} labelContent='From Date' />

                                        </div>

                                        <div className="col-md-4 col-sm-12 sn-header-select-employess-home">
                                            <DatePickerWithoutTimes onChange={handleToDateChange} getDate={selectedToDate} labelContent='To Date' />
                                        </div>

                                        <div className="col-md-3 col-sm-12 sn-header-select-employess-home">
                                            <SearchField onChange={handleSearchField} labelContent='Search by Client details' />
                                        </div>
                                        <div className="col-md-2 col-sm-12 mt-2">
                                            <FormGroup>
                                                <Label for="exampleSelect"></Label>
                                                <div id="exampleSelect" className="sn-call-apply-btn-reset">
                                                    <Button
                                                        type="submit"
                                                        color="danger"
                                                        className=""
                                                        onClick={handleSubmit}
                                                        disabled={isLoading}
                                                    >
                                                        Get Report
                                                    </Button>

                                                </div>
                                            </FormGroup>
                                        </div>
                                    </Row>

                                    <Row className="sn-user-tble-manin-manage">
                                        <Col lg={12}>
                                            <CommanDataTable column={column} tableData={clientReport}
                                                handleSearch={handleSearch} searchText={query}
                                                handlePerRowsChange={handlePerRowsChange}
                                                totalRows={totalRows} currentPage={currentPage}
                                                handlePageChange={handlePageChange}
                                                isLoading={isLoading} inputClass={clientReport && clientReport.length > 0 ? 'd-block' : 'd-none'}
                                            // noData='There are no records to display'
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ClientReport