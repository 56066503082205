export const toCamelCase = (str) => {
    return str
        .split(' ')
        .map((word, index) => {
            if (index === 0) {
                return word.toLowerCase();
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(' ');
};

export const sortTeamsByMemberCount = (teams, order = 'asc') => {
    return teams.sort((a, b) => {
        if (order === 'asc') {
            return a.memberCount - b.memberCount;
        } else if (order === 'desc') {
            return b.memberCount - a.memberCount;
        }
        return 0;
    });
};

export const sortMemberCount = (a, b) => {
    // Convert the memberCount to integers if they are not already numbers
    const memberCountA = parseInt(a.memberCount, 10);
    const memberCountB = parseInt(b.memberCount, 10);

    // Prioritize zeros
    if (memberCountA === 0 && memberCountB !== 0) {
        return -1;
    }
    if (memberCountB === 0 && memberCountA !== 0) {
        return 1;
    }

    // Sort normally
    return memberCountA - memberCountB;
};


export const letterColors = {
    A: '#FF5733',
    B: '#33FF57',
    C: '#3357FF',
    D: '#FF33A1',
    E: '#A133FF',
    F: '#FFD133',
    G: '#33FFF9',
    H: '#F933FF',
    I: '#FF5733',
    J: '#FF9633',
    K: '#FF33C8',
    L: '#57FF33',
    M: '#5733FF',
    N: '#33C8FF',
    O: '#FF8C33',
    P: '#D1FF33',
    Q: '#33FF96',
    R: '#FF3357',
    S: '#33FF8C',
    T: '#338CFF',
    U: '#FF9C33',
    V: '#96FF33',
    W: '#5733FF',
    X: '#33D1FF',
    Y: '#FF33D1',
    Z: '#FF5733'
  };