import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useNavigate } from "react-router-dom";
import {
  GET_EMPLOYEE,
  PLAN_PRICING,
  POST_PROFILE,
} from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
import moment from "moment";
import { useProfileData } from "../../Components/Hooks/UserHooks";
const api = new APIClient();

const extractDescriptions = (descriptionArray) => {
  return descriptionArray
    .map((desc) => {
      const match = desc.match(/\*?([^:*]+)\*?\s*:/);
      if (match) {
        return match[1].trim();
      } else {
        return desc.split("-")[0].trim();
      }
    })
    .filter((desc) => desc);
};

const PlanPackages = () => {
  const navigate = useNavigate();
  const { profileData } = useProfileData();
  const [remainingDays, setRemainingDays] = useState(null);

  useEffect(() => {
    const calculateRemainingDays = () => {
      if (
        profileData?.subscriptionEndDate &&
        profileData?.subscriptionStartDate
      ) {
        const endDate = new Date(profileData?.subscriptionEndDate);
        const startDate = new Date(profileData?.subscriptionStartDate);
        const currentDate = new Date();

        const totalDays = Math.ceil(
          (endDate - startDate) / (1000 * 60 * 60 * 24)
        );
        const remainingDays = Math.ceil(
          (endDate - currentDate) / (1000 * 60 * 60 * 24)
        );
        setRemainingDays(remainingDays > 0 ? remainingDays : 0);
        const interval = setInterval(() => {
          const newCurrentDate = new Date();
          const newRemainingDays = Math.ceil(
            (endDate - newCurrentDate) / (1000 * 60 * 60 * 24)
          );
          setRemainingDays(newRemainingDays);
        }, 1000 * 60 * 60 * 24);

        return () => clearInterval(interval);
      } else {
        setRemainingDays(null);
      }
    };

    calculateRemainingDays();
  }, [profileData]);

  const [planData, setPlanData] = useState([]);
  const [planType, setPlanType] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);

  const getPlanPricing = async () => {
    try {
      const response = await api.get(`${PLAN_PRICING}`);
      if (response.success === true) {
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await api.get(`${POST_PROFILE}`);
      if (response.success === true) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  useEffect(() => {
    const combineData = async () => {
      try {
        const [planData, subPlanData] = await Promise.all([
          getPlanPricing(),
          fetchUserData(),
        ]);
        const processedData = planData?.map((plan) => {
          const commonProperties = {
            ...plan,
            formattedDescriptions: extractDescriptions(plan.description),
            planType: plan.type === "free" ? "basic" : "not free",
            subStartDate: subPlanData.subscriptionStartDate,
            subEndDate: subPlanData.subscriptionEndDate,
          };

          let diffDays = 0;
          if (
            subPlanData &&
            subPlanData.subscriptionStartDate &&
            subPlanData.subscriptionEndDate
          ) {
            const date1 = new Date(subPlanData.subscriptionStartDate);
            const date2 = new Date(subPlanData.subscriptionEndDate);
            const diffTime = Math.abs(date2 - date1);
            diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          }

          if (
            plan.type !== "free" &&
            subPlanData &&
            subPlanData.selectedPlanDetails.planName === "Basic Plan"
          ) {
            commonProperties.subscriptionEndDate =
              subPlanData.subscriptionEndDate;
            commonProperties.subscriptionStartDate =
              subPlanData.subscriptionStartDate;
            commonProperties.organizationStrength =
              subPlanData.organizationStrength;
            commonProperties.subscriptionDurationDays = diffDays;
          }
          return commonProperties;
        });
        setSelectedPlan(subPlanData?.selectedPlan);
        setPlanData(processedData);
        setPlanType(subPlanData);
      } catch (error) {
        console.log("Error combining data:", error);
      }
    };
    combineData();
    getEmployeeData();
  }, []);

  const handleClick = (data) => {
    navigate("/subscription-plan", { state: { data } });
  };

  const handleAddonClick = (data) => {
    navigate("/addon-plan", { state: { data } });
  };

  const hadleFutureClick = (data) => {
    navigate("/future-plan", { state: { data } });
  };

  const getEmployeeData = async () => {
    try {
      const response = await api.get(`${GET_EMPLOYEE}`);
      if (response.success === true) {
        setEmployeeData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const subscriptionDay =
    remainingDays === 0 ? remainingDays : planData[1]?.subscriptionDurationDays;
  const remainingDay = remainingDays > 0 ? "15" : remainingDays;
  return (
    <React.Fragment>
      <div className="page-content sn-plan-summary-main-page">
        <Container fluid>
          <BreadCrumb title="Plan Summary" pageTitle="Pages" />
          {/* <div className="d-flex justify-content-end mt-2">
                        {selectedPlan === 'paid-1' ?
                            <>
                                <Button className='btn btn-danger' onClick={() => hadleFutureClick()}>Future Subcription</Button>
                            </>
                            : ''}
                    </div> */}

          <Row className="justify-content-center mt-4">
            <div className="table-responsive table-card">
              <div className={`pricing-box ribbon-fill left mt-4 ribbon-box`}>
                <Table className="align-middle table-bordered  table-nowrap mb-0 table-right-border">
                  <div className={`ribbon ribbon-primary shadow`}>Active</div>
                  <thead className="table-danger text-center">
                    <tr>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      {planType?.selectedPlanDetails?.planName ===
                        "Basic Plan" && (
                          <th scope="col">Subscription Period</th>
                        )}
                      {planType?.selectedPlanDetails?.planName ===
                        "Free Plan" && <th scope="col">Trial Period</th>}
                      <th scope="col">No of Employee</th>
                      <th scope="col">Plan</th>
                    </tr>
                  </thead>
                  <tbody className="text-center sn-plan-summery-tble-sub">
                    <tr>
                      <td>
                        {planType?.selectedPlanDetails?.planName ===
                          "Basic Plan" &&
                          moment(planData[1]?.subStartDate).format(
                            "DD MMM YYYY"
                          )}
                        {planType?.selectedPlanDetails?.planName ===
                          "Free Plan" &&
                          moment(planData[0]?.subStartDate).format(
                            "DD MMM YYYY"
                          )}
                      </td>
                      <td>
                        {planType?.selectedPlanDetails?.planName ===
                          "Basic Plan" &&
                          moment(planData[1]?.subEndDate).format("DD MMM YYYY")}
                        {planType?.selectedPlanDetails?.planName ===
                          "Free Plan" &&
                          moment(planData[0]?.subEndDate).format("DD MMM YYYY")}
                      </td>
                      <td>
                        {planType?.selectedPlanDetails?.planName ===
                          "Basic Plan"
                          ? subscriptionDay
                          : remainingDay} <span>Days</span>
                      </td>
                      <td>
                        {planType?.selectedPlanDetails?.planName ===
                          "Basic Plan"
                          ? planData[1]?.organizationStrength
                          : '-'}
                      </td>
                      <td className="link-success">
                        <i className="ri ri-check-line">
                          {" "}
                          {planType?.selectedPlanDetails?.planName}
                        </i>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Row>

          {/* <Row className='mt-5'>
                        <Col lg={4}>
                            <div className="mb-4">
                                <h4 className="fw-bold fs-22">Plans & Pricing</h4>
                                <p className="text-muted mb-4 fs-15">{selectedPlan === 'paid-1' ? 'Your current subscription: Paid Plan' : 'Your current subscription: No subscription'}</p>
                            </div>
                        </Col>
                    </Row> */}

          <Row style={{ marginTop: "80px" }}>
            {selectedPlan === "paid-1" && remainingDays > 0 && (
              <>
                <Col lg={12}>
                  <h5 style={{ fontWeight: "700" }}>Available Addon</h5>
                  <Card className="pricing-box ribbon-box ribbon-fill text-center mt-3">
                    <CardBody className="h-100">
                      <div
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                        }}
                      >
                        <div className="text-success sn-notification-icon">
                          <i className="ri-checkbox-circle-fill fs-15"></i>
                          &nbsp;
                          <h5 className="mb-1">Call Monitoring</h5>
                        </div>
                        <div>
                          <h2 className="">
                            AED 24 <small className="fs-15"> /User/Month</small>
                          </h2>
                        </div>
                      </div>
                      <div className="sn-heading-pera mt-0">
                        <h6>
                          The call tracking plan includes tracking of SIM calls
                          as well as access to the analytics dashboard and
                          reports.
                        </h6>
                        <h6>
                          Note :- Currently you have {employeeData.length} out
                          of {profileData?.organizationStrength} employee(s).
                        </h6>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={12}>
                  <Card className="pricing-box ribbon-box ribbon-fill text-center mt-3">
                    <CardBody className="h-100">
                      <div
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                        }}
                      >
                        <div className="text-success sn-notification-icon">
                          <i className="ri-checkbox-circle-fill fs-15"></i>
                          &nbsp;
                          <h5 className="mb-1">Call Recording Storage</h5>
                        </div>
                        <div>
                          <h2 className="">
                            AED 14 <small className="fs-15"> / GB /Month</small>
                          </h2>
                        </div>
                      </div>
                      <div className="sn-heading-pera mt-0">
                        <h6>
                          Keep a record of important conversations with call
                          recording capabilities, ensuring you don't miss
                          critical details
                        </h6>
                        <h6>
                          Note :- Available Storage space 33.50 GB out of 45 GB.
                        </h6>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={2} className="mb-3">
                  <button
                    onClick={() => handleAddonClick(planData[1])}
                    className="btn btn-danger w-100"
                  >
                    Add Ons
                  </button>
                </Col>
              </>
            )}

            {(selectedPlan === "free" || remainingDays === 0) && (
              <>
                <Col lg={4} style={{ margin: "auto" }}>
                  <Card
                    className={`pricing-box ribbon-fill left mt-4 ribbon-box`}
                  >
                    {/* <div className={`ribbon ribbon-primary shadow`}
                                    >Active</div> */}
                    <CardBody className="bg-light m-2 p-4">
                      <div className="d-flex align-items-center mb-3">
                        <div className="flex-grow-1">
                          <h5 className="mb-0 fw-bold">
                            {planData[1]?.planName}
                          </h5>
                        </div>
                        <div className="ms-auto">
                          <h2 className="month mb-0">
                          AED {planData[1]?.pricingPerUser}{" "}
                            <small className="fs-15">/User/Month</small>
                          </h2>
                        </div>
                      </div>
                      <ul
                        className="list-unstyled vstack gap-3"
                        style={{ paddingLeft: "80px" }}
                      >
                        {planData[1]?.formattedDescriptions?.map((item) => {
                          return (
                            <>
                              <li>
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0 text-success me-1 d-flex align-items-center">
                                    <i className="ri-checkbox-circle-fill fs-15"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <b>{item}</b>
                                  </div>
                                </div>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                      <div className="mt-3 pt-2 sales-ninja-sub-btn">
                        <button
                          onClick={() => handleClick(planData[1])}
                          className="btn btn-success w-100"
                        >
                          Subscribe
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PlanPackages;
