// import React, { useEffect, useRef, useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import BreadCrumb from "../../Components/Common/BreadCrumb";
// import { Card } from "reactstrap";
// import { ToastContainer , toast} from "react-toastify";

// const SetPerformance = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [inputValue, setInputValue] = useState("");
//   const [activeIndex, setActiveIndex] = useState(null);
//   const [totalValue, setTotalValue] = useState(0);
//   const inputRef = useRef(null);

//   const optionArray = ["banana", "apple", "peach", "fruit", "mango", "empty"];
//   const [selectedOptions, setSelectedOptions] = useState({});
//   const [inputValues, setInputValues] = useState({});

//   const handleBoxClick = (index) => {
//     if (selectedOptions[index]) {
//       setActiveIndex(index);
//       setIsOpen(true);
//       setInputValue(inputValues[index] || ""); // Set the input value to the current input value for the box
//     }
//   };

//   const handleInputChange = (e) => {
//     const newValue = parseInt(e.target.value) || "";
//     const remainingValue = 100 - totalValue + (inputValues[activeIndex] || ""); // Account for the existing value in the current input

//     if (newValue <= remainingValue) {
//       setInputValue(newValue);
//     } else {
//       setInputValue(remainingValue); // Set it to the remaining allowed value if it exceeds
//       // alert("Please enter value less than " + remainingValue);
//       toast.success("Please enter value less than " + remainingValue, {
//         autoClose: 1000,
//         position: toast.POSITION.TOP_RIGHT,
//       });
//     }
//   };

//   const handleInputBlur = () => {
//     if (inputValue) {
//       const newTotal =
//         totalValue - (inputValues[activeIndex] || 0) + parseInt(inputValue);
//       setTotalValue(newTotal);
//       setInputValues({
//         ...inputValues,
//         [activeIndex]: parseInt(inputValue),
//       });
//     }
//     setIsOpen(false);
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter" && inputValue) {
//       handleInputBlur();
//     }
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (inputRef.current && !inputRef.current.contains(event.target)) {
//         setIsOpen(false);
//         if (inputValue) {
//           handleInputBlur();
//         }
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [inputValue]);

//   const handleSelect = (e, selectName) => {
//     setSelectedOptions({
//       ...selectedOptions,
//       [selectName]: e.target.value,
//     });
//   };

//   const getFilteredOptions = (excludeOption) => {
//     const selectedValues = Object.values(selectedOptions);
//     return optionArray.filter(
//       (option) => option === excludeOption || !selectedValues.includes(option)
//     );
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const combinedData = {};
//     Object.keys(selectedOptions).forEach((index) => {
//       const selectedValue = selectedOptions[index];
//       const inputValue = inputValues[index];
//       if (selectedValue) {
//         combinedData[selectedValue] = inputValue || 0;
//       }
//     });
//     console.log("Combined Data:", combinedData);
//   };

//   return (
//     <>
//       <ToastContainer />
//       <div className="page-content sn-employees-managment">
//         <Container fluid>
//           <BreadCrumb title="Set Performance Criteria" pageTitle="" />
//           <Row>
//             <Col lg={12}>
//               <Card className="h-100 d-flex flex-column justify-content-center">
//                 <Container>
//                   <form onSubmit={handleSubmit}>
//                     <div className="row g-5">
//                       {[0, 1, 2, 3].map((index) => (
//                         <div className="col-md-6 col-sm-12" key={index} style={{display:"flex", justifyContent:"center"}}>
//                           <div
//                             className="box-container"
//                             key={index}
//                             onClick={() => handleBoxClick(index)}
//                           >
//                             <div
//                               className={`me-2 shadow p-3 ${
//                                 inputValues[index] ? "unlock" : "lock"
//                               }`}
//                             ></div>
//                             <div>
//                               <select
//                                 className="form-select box-select w-75"
//                                 aria-label="Default select example"
//                                 onChange={(e) => handleSelect(e, index)}
//                                 value={selectedOptions[index]}
//                               >
//                                 <option value="">Open this select menu</option>
//                                 {getFilteredOptions(selectedOptions[index]).map(
//                                   (option, i) => (
//                                     <option key={i} value={option}>
//                                       {option}
//                                     </option>
//                                   )
//                                 )}
//                               </select>
//                             </div>
//                             {isOpen && activeIndex === index && (
//                               <div
//                                 className={`input-field-box d-flex justify-content-end align-items-center ${
//                                   isOpen ? "open" : "close"
//                                 }`}
//                                 ref={inputRef}
//                               >
//                                 <input
//                                   type="number"
//                                   className="input-field border-0"
//                                   value={inputValue}
//                                   onChange={handleInputChange}
//                                   onBlur={handleInputBlur}
//                                   onKeyDown={handleKeyDown}
//                                   placeholder={`Type a value (0 - ${
//                                     100 -
//                                     totalValue +
//                                     (inputValues[activeIndex] || 0)
//                                   })`}
//                                 />
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                       ))}
//                       <div className="d-flex justify-content-center mt-3">
//                         <button
//                           type="submit"
//                           className="btn btn-primary"
//                         >
//                           Submit
//                         </button>
//                       </div>
//                     </div>
//                   </form>
//                 </Container>
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// };

// export default SetPerformance;

import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, CardBody } from "react-bootstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Button, Card, CardHeader } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

// Api
import axios from "axios";

import { setPDATA } from "../../store/teams/action";

// Redux
import { useDispatch } from "react-redux";

const SetPerformance = () => {

  const dispatch = useDispatch()
  const [selectedItems, setSelectedItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [totalValue, setTotalValue] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [WeightageData, setWeightageData] = useState();



  const inputRef = useRef(null);

  // Options for the select
  const options = [
    "totalCall",
    "totalDuration",
    "connectedCalls",
    "totalIncomingCall",
    "totalIncomingConnectedCall",
    "totalIncomingCallDuration",
    "totalOutgoingCall",
    "totalOutgoingConnectedCall",
    "totalOutgoingCallDuration",
    "totalMissedCall",
    "totalRejectedCall",
    "totalNotPickedUpByClient",
    "avrerageCallDuration"
  ];

  // Handle checkbox change
  const handleCheckboxChange = (option) => {
    if (selectedItems.includes(option)) {
      // Deselect item
      setSelectedItems(selectedItems.filter((item) => item !== option));
    } else {
      // Select item
      setSelectedItems([...selectedItems, option]);
    }
  };

  const handleBoxClick = (index) => {
    setActiveIndex(index);
    setIsOpened(true);
    setInputValue(inputValues[index] || ""); // Set the input value to the current input value for the box
  };

  const handleInputChange = (e) => {
    const newValue = parseInt(e.target.value) || "";
    const remainingValue = 100 - totalValue + (inputValues[activeIndex] || ""); // Account for the existing value in the current input
    if (newValue <= remainingValue) {
      setInputValue(newValue);
    } else {
      setInputValue(remainingValue); // Set it to the remaining allowed value if it exceeds
      toast.success("Please enter value less than " + remainingValue, {
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleInputBlur = () => {
    if (inputValue) {
      const newTotal =
        totalValue - (inputValues[activeIndex] || 0) + parseInt(inputValue);
      setTotalValue(newTotal);
      setInputValues({
        ...inputValues,
        [activeIndex]: parseInt(inputValue),
      });
      setSelectedOptions([...selectedOptions, options[activeIndex]]);
    }
    setIsOpened(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue) {
      handleInputBlur();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsOpened(false);
        if (inputValue) {
          handleInputBlur();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputValue]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValues === undefined || inputValues === null) {
      alert("Please select at least one item");
      return;
    }
    const combinedData = {};
    selectedItems.forEach((option, index) => {
      const inputValue = inputValues[index];
      if (option) {
        combinedData[option] = inputValue || 0;
      }
    });
    setWeightageData(combinedData)
    try {
      const res = await axios.post('http://192.168.1.5:5000/data', combinedData);
      console.log(res,5676777)
      dispatch(setPDATA(res))
    } catch (err) {
      console.log(err.message)
    }

   
  };



  return (
    <>
      <ToastContainer />
      <div className="page-content sn-employees-managment">
        <Container fluid>
          <BreadCrumb title="Set Performance Criteria" pageTitle="" />
          <Row>
            <Col lg={12}>
              <Card className="h-100 d-flex flex-column justify-content-center">
                <CardHeader>
                  <div className="custom-select-container">
                    {/* Custom select control */}
                    <Button
                      className="btn btn-danger select-btn"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Select
                      items
                    </Button>

                    {/* Options list */}
                    <div>What ever you total wages were must be divisible by 100</div>
                    {isOpen && (
                      <div className="options-list">
                        
                        {options.map((option, index) => (
                          <div key={index} className="option-item">
                            <input
                              type="checkbox"
                              id={`checkbox-${index}`}
                              checked={selectedItems.includes(option)}
                              onChange={() => handleCheckboxChange(option)}
                            />
                            <label htmlFor={`checkbox-${index}`}>
                              {option}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </CardHeader>
                <CardBody
                  className="sn-team-managment-tble mt-0 pt-0"
                  style={{ height: "100vh" }}
                >
                  <form onSubmit={handleSubmit}>
                    <div className="row g-3 mt-1">
                      {selectedItems.map((link, index) => (
                        <div
                          className="col-md-6 col-sm-12"
                          key={index}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div
                            className="box-container"
                            key={index}
                            onClick={() => handleBoxClick(index)}
                          >
                            <div
                              className={`me-2 shadow p-3 ${
                                inputValues[index] ? "unlock" : "lock"
                              }`}
                            ></div>
                            <div>{link}</div>
                            {isOpened && activeIndex === index && (
                              <div
                                className={`input-field-box d-flex justify-content-end align-items-center ${
                                  isOpened ? "open" : "close"
                                }`}
                                ref={inputRef}
                              >
                                <input
                                  type="number"
                                  className="input-field border-0"
                                  value={inputValue}
                                  onChange={handleInputChange}
                                  onBlur={handleInputBlur}
                                  onKeyDown={handleKeyDown}
                                  placeholder="Weightage"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      {selectedItems.length > 0 && (
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-success"
                            id="add-btn"
                          >
                            Submit
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SetPerformance;
